<template>
	<div class="">
		<santuoMap style="height: 100%;width: 100%;" ref="map" :jkList="jkList" @playVideo="showVideo"></santuoMap>
		<div class="botto flex justc alit">
			<img :src="menuIndex != 1 ? 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E7%BB%84%2012714%402x%282%29.png' : 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E7%BB%84%2012714%402x%283%29.png'"
				class="menu" alt="" @click="changeMenuIndex(1)" />
			<img :src="menuIndex == 2 ? 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E7%BB%84%2012624%402x%282%29.png' : 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E7%BB%84%2012624%402x%281%29.png'"
				class="menu" alt="" @click="changeMenuIndex(2)" />
			<img :src="menuIndex == 3 ? 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E7%BB%84%2012625%402x%284%29.png' : 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E7%BB%84%2012625%402x%283%29.png'"
				class="menu" alt="" @click="changeMenuIndex(3)" />
			<img :src="menuIndex == 4 ? 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E7%BB%84%2012623%402x%285%29.png' : 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E7%BB%84%2012623%402x%284%29.png'"
				class="menu" alt="" @click="changeMenuIndex(4)" />
			<img :src="menuIndex == 5 ? 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E7%BB%84%2012626%402x%284%29.png' : 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E7%BB%84%2012626%402x%283%29.png'"
				class="menu" alt="" @click="changeMenuIndex(5)" />
			<img :src="menuIndex == 6 ? 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E7%BB%84%2012627%402x%285%29.png' : 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E7%BB%84%2012627%402x%284%29.png'"
				class="menu" alt="" @click="changeMenuIndex(6)" />
		</div>

		<div class="popup-info flexCol alit" v-if="menuIndex == 9" style="overflow: hidden;height: 80%;">
			<img src="../../static/back.png" class="popup-back" style="width: 40px;height: 40px;" alt=""
				@click="changeMenuIndex(3)" />
			<div class="title" style="margin: 3vh 0 0 0;">道路安全识别</div>
			<div class="flex justb zzh-title" style="margin-top: 20rpx;font-size: 14px;">
				<div class="" style="width: 75px;text-align: center;">
					时间
				</div>
				<div class="" style="width: 70px;text-align: center;">
					道路卡口
				</div>
				<div class="">
					车牌号
				</div>
				<div class="">
					车辆类型
				</div>
				<div class="">
					车辆颜色
				</div>
				<div class="">
					图片
				</div>
			</div>
			<div class="zzh-content2 flexCol alit">
				<vue-seamless-scroll :data="zzdhList" class="warp2" :class-option="classOption">
					<ul class="item">
						<li v-for="(item, index) in carList" :key="index" style="height: 40px;">
							<div class="flex justb zzh-title2" style="">
								<div class="" style="width: 80px;">
									2024-06-12 13:18
								</div>
								<div class="" style="width: 70px;">
									{{item.addr}}
								</div>
								<div class="" style="width: 70px;">
									渝A{{generateLicensePlateWithoutProvince()}}
								</div>
								<div class="" style="width: 80px;">
									轿车 | {{item.carType}}
								</div>
								<div class="" style="width: 40px;">
									{{item.carColor}}
								</div>
								<div class="" style="width: 40px;">
									<img :src="item.img" style="width:44px;height: 44px;" alt="" />
								</div>
							</div>

						</li>
					</ul>
				</vue-seamless-scroll>
			</div>
		</div>

		<div class="popup-info flexCol alit" v-if="menuIndex == 10" style="overflow: hidden;height: 80%;">
			<div class="title" style="margin: 3vh 0 1vh 0;">库区管理</div>
			<div class="zzh-content flexCol alit">
				<vue-seamless-scroll :data="zzdhList" class="warp" :class-option="classOption">
					<ul class="item">
						<li v-for="(item, index) in zzdhList" :key="index" class="flex alit justb"
							style="height: 40px;">
							<div class="flex alit justb" style="width: 90%;">
								<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/supervise/%E7%BB%84%2012629%402x.png"
									style="width:  54px;height: 54px;margin-left: 30px;" mode="">
								</img>
								<div class="zzh-name" style="margin: 0 20px;">三沱库区</div>
								<div class="zzh-name">
									<!-- 巡河员：伍彬 -->
								</div>
								<div class="zzh-name" style="margin: 0 10px  0 30px;">
									2024-04-18 13:18
								</div>
								<div class="yc" v-if="index %5 == 0">异常</div>
								<div class="zc" v-else>正常</div>
							</div>

						</li>
					</ul>
				</vue-seamless-scroll>
			</div>
		</div>
		<div class="popup-info flexCol alit" v-if="menuIndex == 11" style="overflow: hidden;height: 80%;">
			<div class="title" style="margin: 3vh 0 1vh 0;">森林防火</div>
			<div class="zzh-content flexCol alit">
				<vue-seamless-scroll :data="zzdhList" class="warp" :class-option="classOption">
					<ul class="item">
						<li v-for="(item, index) in zzdhList" :key="index" class="flex alit justb"
							style="height: 40px;">
							<div class="flex alit justb" style="width: 90%;">
								<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/supervise/%E7%BB%84%402x%20%281%29.png"
									style="width:  54px;height: 54px;margin-left: 30px;" mode="">
								</img>
								<div class="zzh-name" style="margin: 0 20px;">防火点{{index+1}}</div>
								<div class="zzh-name">
									<!-- 巡河员：伍彬 -->
								</div>
								<div class="zzh-name" style="margin: 0 10px  0 30px;">
									2024-04-18 13:18
								</div>
								<div class="yc" v-if="index %5 == 0">异常</div>
								<div class="zc" v-else>正常</div>
							</div>

						</li>
					</ul>
				</vue-seamless-scroll>
			</div>
		</div>
		<div class="popup-info flexCol alit" v-if="menuIndex == 8" style="overflow: hidden;height: 80%;">
			<div class="title" style="margin: 3vh 0 1vh 0;">巡河管理</div>
			<div class="zzh-content flexCol alit">
				<vue-seamless-scroll :data="zzdhList" class="warp" :class-option="classOption">
					<ul class="item">
						<li v-for="(item, index) in zzdhList" :key="index" class="flex alit justb"
							style="height: 40px;">
							<div class="flex alit">
								<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/supervise/%E5%AE%B9%E5%99%A8.png"
									style="width:  38px;height: 38px;margin-left: 30px;" mode="">
								</img>
								<div class="zzh-name" style="margin: 0 20px;">巡河记录</div>
								<div class="zzh-name">
									巡河员：伍彬
								</div>
								<div class="zzh-name" style="margin: 0 10px  0 30px;">
									2024-04-18 13:18
								</div>
								<div class="yc" v-if="index %5 == 0">异常</div>
								<div class="zc" v-else>正常</div>
							</div>

						</li>
					</ul>
				</vue-seamless-scroll>
			</div>
		</div>
		<div class="popup-info flexCol alit" v-if="menuIndex == 12" style="overflow: hidden;height: 80%;">
			<div class="title" style="margin: 3vh 0 1vh 0;">库区管理</div>
			<div class="zzh-content flexCol alit">
				<vue-seamless-scroll :data="zzdhList" class="warp" :class-option="classOption">
					<ul class="item">
						<li v-for="(item, index) in zzdhList" :key="index" class="flex alit justb"
							style="height: 40px;">
							<div class="flex alit justb" style="width: 90%;">
								<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/supervise/%E7%BB%84%402x%20%282%29.png"
									style="width:  54px;height: 54px;margin-left: 30px;" mode="">
								</img>
								<div class="zzh-name" style="margin: 0 20px;">保护点{{index+1}}</div>
								<div class="zzh-name">
									<!-- 巡河员：伍彬 -->
								</div>
								<div class="zzh-name" style="margin: 0 10px  0 30px;">
									2024-04-18 13:18
								</div>
								<div class="yc" v-if="index %5 == 0">异常</div>
								<div class="zc" v-else>正常</div>
							</div>

						</li>
					</ul>
				</vue-seamless-scroll>
			</div>
		</div>

		<div class="popup-info flexCol alit"
			v-if="menuIndex == 1 || menuIndex == 2|| menuIndex == 3|| menuIndex == 4|| menuIndex == 5|| menuIndex == 6"
			style="overflow: hidden;height:80%;">
			<img src="../../static/close2.png" class="popup-close" style="width: 30px;height: 30px;" alt=""
				@click="changeMenuIndex(menuIndex)" />
			<div class="fram1-content flexCol alit justc">
				<div class="flex">
					<div class="flex">
						<img src="../../static/zl/jk.png" style="width: 50px;height: 54px;" mode="">
						</img>
						<div class="" style="margin-left: 14px;">
							<div class="jk-title">
								实时监控个数
							</div>
							<div class="flex justb" style="width: 85px;">
								<div class="jk-num">5</div>
								<div class="jk-desc">个</div>
							</div>
						</div>
					</div>
					<div class="flex" style="margin-left: 20px;cursor: pointer;"
						v-if="menuIndex == 1 || menuIndex == 3">
						<img src="../../static/zl/组@2x.png" style="width: 50px;height: 54px;" mode=""
							v-if="menuIndex == 1">
						</img>
						<img src="../../static/zl/组@2x(1).png" style="width: 50px;height: 54px;" mode=""
							v-if="menuIndex == 3" @click="changeMenuIndex(9)">
						</img>
						<div class="" style="margin-left: 14px;">
							<div class="jk-title" v-if="menuIndex == 1">
								滑坡区域个数
							</div>
							<div class="jk-title" v-if="menuIndex == 3" @click="changeMenuIndex(9)">
								违章次数
							</div>

							<div class="flex justb" style="width: 85px;" v-if="menuIndex == 1">
								<div class="jk-num">4</div>
								<div class="jk-desc">个</div>
							</div>
							<div class="flex justb" style="width: 85px;" v-if="menuIndex == 3"
								@click="changeMenuIndex(9)">
								<div class="jk-num">106</div>
								<div class="jk-desc">次</div>
							</div>
						</div>
					</div>
					<div class="flex" style="margin-left: 20px;">
						<img src="../../static/zl/组@2x(2).png" style="width: 50px;height: 54px;" mode="">
						</img>
						<div class="" style="margin-left: 14px;">
							<div class="jk-title">
								管控面积
							</div>
							<div class="flex justb" style="width: 85px;">
								<div class="jk-num">
									{{menuIndex == 1 ? 600 : menuIndex == 2 ? 120 :menuIndex == 3 ? 450 :menuIndex == 4 ? 350 :menuIndex == 5 ? 540 : 120}}
								</div>
								<div class="jk-desc">km²</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 	<img src="../../static/zl/组@2x(1).png" style="width: 50px;height: 54px;" mode="">
			</img> -->

			<div class="zzh-content flexCol alit" style="height: 75%;" @click="rankBtn($event)">
				<vue-seamless-scroll :data="zzdhList" class="warp" :class-option="classOption">
					<ul class="item">
						<li style="background-color: rgba(255, 255, 255, 0);padding: 0;">
							<div v-for="(item,index) in jkList" :data="JSON.stringify(item)" class="jk-list flex alit "
								v-if="item.type == (menuIndex == 1 ? 6 : menuIndex == 2 ? 1 :menuIndex == 3 ? 5 :menuIndex == 4 ? 3 :menuIndex == 5 ? 2 : 4)">
								<img src="../../static/zl/jkqy.png" style="width: 38px;height: 38px;" mode=""></img>
								<div class="jk-add">
									<!-- {{item.title}} -->
									{{item.title}}
								</div>
							</div>
						</li>
					</ul>
				</vue-seamless-scroll>
			</div>
		</div>

		<div class="qp flexCol alit justc" v-if="qpShow">
			<img src="../../static/close.png" class="close" mode="" @click="qpShow = false"></img>
			<div class="" id="mse" style="width: 50vw;height: 50vh;"></div>
		</div>


	</div>
</template>

<script>
	import santuoMap from '../components/superviseMap.vue'
	import vueSeamlessScroll from 'vue-seamless-scroll'
	import Player from 'xgplayer'
	import FlvPlugin from 'xgplayer-flv'
	import HlsPlugin from 'xgplayer-hls'
	import "xgplayer/dist/index.min.css"
	export default {
		components: {
			santuoMap,
			vueSeamlessScroll,
		},
		data() {
			return {
				qpShow: false,
				classOption: {
					limitMoveNum: 10,
					hoverStop: true,
					waitTime: 3000,
					step: 0.5
				},
				menuIndex: 0,
				carList: [{
					carColor: '黑色',
					carType: '大众',
					addr: '村委会卡口',
					img: 'https://img1.baidu.com/it/u=471825515,3086246515&fm=253&fmt=auto&app=138&f=JPEG?w=640&h=500'
				}, {
					carColor: '白色',
					carType: '长安',
					addr: '村委会卡口',
					img: 'https://img0.baidu.com/it/u=2726770329,3790247409&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=391'
				}, {
					carColor: '白色',
					carType: '丰田',
					addr: '村委会卡口',
					img: 'https://img0.baidu.com/it/u=2639567587,3227934068&fm=253&fmt=auto&app=138&f=JPEG?w=640&h=500'
				}, {
					carColor: '白色',
					carType: '雪佛兰',
					addr: '村委会卡口',
					img: 'https://img0.baidu.com/it/u=3051325277,2949747106&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=391'
				}, {
					carColor: '白色',
					carType: '福特',
					addr: '村委会卡口',
					img: 'https://img0.baidu.com/it/u=2112558284,2947697818&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=391'
				}, {
					carColor: '白色',
					carType: '丰田',
					addr: '村委会卡口',
					img: 'https://img0.baidu.com/it/u=2639567587,3227934068&fm=253&fmt=auto&app=138&f=JPEG?w=640&h=500'
				}, {
					carColor: '白色',
					carType: '福特',
					addr: '村委会卡口',
					img: 'https://img0.baidu.com/it/u=2112558284,2947697818&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=391'
				}, {
					carColor: '白色',
					carType: '雪佛兰',
					addr: '村委会卡口',
					img: 'https://img0.baidu.com/it/u=3051325277,2949747106&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=391'
				}, {
					carColor: '白色',
					carType: '福特',
					addr: '村委会卡口',
					img: 'https://img0.baidu.com/it/u=2112558284,2947697818&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=391'
				}, {
					carColor: '白色',
					carType: '福特',
					addr: '村委会卡口',
					img: 'https://img0.baidu.com/it/u=2112558284,2947697818&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=391'
				}, {
					carColor: '白色',
					carType: '雪佛兰',
					addr: '村委会卡口',
					img: 'https://img0.baidu.com/it/u=3051325277,2949747106&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=391'
				}, {
					carColor: '白色',
					carType: '福特',
					addr: '村委会卡口',
					img: 'https://img0.baidu.com/it/u=2112558284,2947697818&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=391'
				}],
				zzdhList: [{
					name: '奉节县驰浩水果种植有限公司',
					xl: '450',
					m: 311.9
				}, {
					name: '奉节县驰浩水果种植有限公司',
					xl: '450',
					m: 311.9
				}, {
					name: '奉节县驰浩水果种植有限公司',
					xl: '450',
					m: 311.9
				}, {
					name: '奉节县驰浩水果种植有限公司',
					xl: '450',
					m: 311.9
				}, {
					name: '奉节县驰浩水果种植有限公司',
					xl: '450',
					m: 311.9
				}, {
					name: '奉节县驰浩水果种植有限公司',
					xl: '450',
					m: 311.9
				}, {
					name: '奉节县驰浩水果种植有限公司',
					xl: '450',
					m: 311.9
				}, {
					name: '奉节县驰浩水果种植有限公司',
					xl: '450',
					m: 311.9
				}, {
					name: '奉节县驰浩水果种植有限公司',
					xl: '450',
					m: 311.9
				}, {
					name: '奉节县驰浩水果种植有限公司',
					xl: '450',
					m: 311.9
				}, {
					name: '奉节县驰浩水果种植有限公司',
					xl: '450',
					m: 311.9
				}, {
					name: '奉节县驰浩水果种植有限公司',
					xl: '450',
					m: 311.9
				}, {
					name: '奉节县驰浩水果种植有限公司',
					xl: '450',
					m: 311.9
				}, {
					name: '奉节县驰浩水果种植有限公司',
					xl: '450',
					m: 311.9
				}, {
					name: '奉节县驰浩水果种植有限公司',
					xl: '450',
					m: 311.9
				}, {
					name: '奉节县驰浩水果种植有限公司',
					xl: '450',
					m: 311.9
				}, {
					name: '奉节县驰浩水果种植有限公司',
					xl: '450',
					m: 311.9
				}, {
					name: '奉节县驰浩水果种植有限公司',
					xl: '450',
					m: 311.9
				}],
				videoList: ['河道监控', '森林防火监控', '库区监控', '长江监控', '道路监控'],
				jkList: [{
					//9号监控
					title: '河道监控1',
					videoUrl: 'https://rtmp02open.ys7.com:9188/v3/openlive/AG9618152_1_1.flv?expire=1737100100&id=669931433170571264&t=a93c870e7dbe10be44a5ee134dd1e2f84273eba9c74588b4a698d279937d7acc&ev=100',
					videoType: 1,
					type: 1,
					location: ''
				}, {
					//11号监控
					title: '河道监控2',
					videoUrl: 'https://open.ys7.com/v3/openlive/AG9618162_1_1.m3u8?expire=1737165686&id=670206519184297984&t=4261702e021846d56f0b454ee79444cb5fe07d83a0f335d1c88e5f2970776f1c&ev=100',
					videoType: 2,
					type: 1,
					location: ''
				}, {
					//12号监控
					title: '河道监控3',
					videoUrl: 'https://open.ys7.com/v3/openlive/AG6270426_1_1.m3u8?expire=1737165748&id=670206780969586688&t=9f09e592215fb2942fcdd60742af97425649f37a159def1af61ee26683ddee07&ev=100',
					videoType: 2,
					type: 1,
					location: ''
				}, {
					//13号监控
					title: '河道监控4',
					videoUrl: 'https://open.ys7.com/v3/openlive/AG9618100_1_1.m3u8?expire=1737165764&id=670206847125590016&t=cb140ef0ef0ea5cdfb1adfbd431ca7f3ca9187039465d985d71254b465e20c54&ev=100',
					videoType: 2,
					type: 1,
					location: ''
				}, {
					//14号监控
					title: '河道监控5',
					videoUrl: 'https://open.ys7.com/v3/openlive/AG6270313_1_1.m3u8?expire=1737165774&id=670206889040019456&t=1bf47400335e2a3e071107cf3b7a0b0e903e79ea00f1a0533563336a00c6e228&ev=100',
					videoType: 2,
					type: 1,
					location: ''
				}, {
					//9号监控
					title: '森林防火监控1',
					videoUrl: 'https://rtmp02open.ys7.com:9188/v3/openlive/AG9618152_1_1.flv?expire=1737100100&id=669931433170571264&t=a93c870e7dbe10be44a5ee134dd1e2f84273eba9c74588b4a698d279937d7acc&ev=100',
					videoType: 1,
					type: 2,
					location: ''
				}, {
					//11号监控
					title: '森林防火监控2',
					videoUrl: 'https://open.ys7.com/v3/openlive/AG9618162_1_1.m3u8?expire=1737165686&id=670206519184297984&t=4261702e021846d56f0b454ee79444cb5fe07d83a0f335d1c88e5f2970776f1c&ev=100',
					videoType: 2,
					type: 2,
					location: ''
				}, {
					//12号监控
					title: '森林防火监控3',
					videoUrl: 'https://open.ys7.com/v3/openlive/AG6270426_1_1.m3u8?expire=1737165748&id=670206780969586688&t=9f09e592215fb2942fcdd60742af97425649f37a159def1af61ee26683ddee07&ev=100',
					videoType: 2,
					type: 2,
					location: ''
				}, {
					//13号监控
					title: '森林防火监控4',
					videoUrl: 'https://open.ys7.com/v3/openlive/AG9618100_1_1.m3u8?expire=1737165764&id=670206847125590016&t=cb140ef0ef0ea5cdfb1adfbd431ca7f3ca9187039465d985d71254b465e20c54&ev=100',
					videoType: 2,
					type: 2,
					location: ''
				}, {
					//14号监控
					title: '森林防火监控5',
					videoUrl: 'https://open.ys7.com/v3/openlive/AG6270313_1_1.m3u8?expire=1737165774&id=670206889040019456&t=1bf47400335e2a3e071107cf3b7a0b0e903e79ea00f1a0533563336a00c6e228&ev=100',
					videoType: 2,
					type: 2,
					location: ''
				}, {
					//9号监控
					title: '库区监控1',
					videoUrl: 'https://rtmp02open.ys7.com:9188/v3/openlive/AG9618152_1_1.flv?expire=1737100100&id=669931433170571264&t=a93c870e7dbe10be44a5ee134dd1e2f84273eba9c74588b4a698d279937d7acc&ev=100',
					videoType: 1,
					type: 3,
					location: ''
				}, {
					//11号监控
					title: '库区监控2',
					videoUrl: 'https://open.ys7.com/v3/openlive/AG9618162_1_1.m3u8?expire=1737165686&id=670206519184297984&t=4261702e021846d56f0b454ee79444cb5fe07d83a0f335d1c88e5f2970776f1c&ev=100',
					videoType: 2,
					type: 3,
					location: ''
				}, {
					//12号监控
					title: '库区监控3',
					videoUrl: 'https://open.ys7.com/v3/openlive/AG6270426_1_1.m3u8?expire=1737165748&id=670206780969586688&t=9f09e592215fb2942fcdd60742af97425649f37a159def1af61ee26683ddee07&ev=100',
					videoType: 2,
					type: 3,
					location: ''
				}, {
					//13号监控
					title: '库区监控4',
					videoUrl: 'https://open.ys7.com/v3/openlive/AG9618100_1_1.m3u8?expire=1737165764&id=670206847125590016&t=cb140ef0ef0ea5cdfb1adfbd431ca7f3ca9187039465d985d71254b465e20c54&ev=100',
					videoType: 2,
					type: 3,
					location: ''
				}, {
					//14号监控
					title: '库区监控5',
					videoUrl: 'https://open.ys7.com/v3/openlive/AG6270313_1_1.m3u8?expire=1737165774&id=670206889040019456&t=1bf47400335e2a3e071107cf3b7a0b0e903e79ea00f1a0533563336a00c6e228&ev=100',
					videoType: 2,
					type: 3,
					location: ''
				}, {
					//9号监控
					title: '长江监控1',
					videoUrl: 'https://rtmp02open.ys7.com:9188/v3/openlive/AG9618152_1_1.flv?expire=1737100100&id=669931433170571264&t=a93c870e7dbe10be44a5ee134dd1e2f84273eba9c74588b4a698d279937d7acc&ev=100',
					videoType: 1,
					type: 4,
					location: ''
				}, {
					//11号监控
					title: '长江监控2',
					videoUrl: 'https://open.ys7.com/v3/openlive/AG9618162_1_1.m3u8?expire=1737165686&id=670206519184297984&t=4261702e021846d56f0b454ee79444cb5fe07d83a0f335d1c88e5f2970776f1c&ev=100',
					videoType: 2,
					type: 4,
					location: ''
				}, {
					//12号监控
					title: '长江监控3',
					videoUrl: 'https://open.ys7.com/v3/openlive/AG6270426_1_1.m3u8?expire=1737165748&id=670206780969586688&t=9f09e592215fb2942fcdd60742af97425649f37a159def1af61ee26683ddee07&ev=100',
					videoType: 2,
					type: 4,
					location: ''
				}, {
					//13号监控
					title: '长江监控4',
					videoUrl: 'https://open.ys7.com/v3/openlive/AG9618100_1_1.m3u8?expire=1737165764&id=670206847125590016&t=cb140ef0ef0ea5cdfb1adfbd431ca7f3ca9187039465d985d71254b465e20c54&ev=100',
					videoType: 2,
					type: 4,
					location: ''
				}, {
					//14号监控
					title: '长江监控5',
					videoUrl: 'https://open.ys7.com/v3/openlive/AG6270313_1_1.m3u8?expire=1737165774&id=670206889040019456&t=1bf47400335e2a3e071107cf3b7a0b0e903e79ea00f1a0533563336a00c6e228&ev=100',
					videoType: 2,
					type: 4,
					location: ''
				}, {
					//9号监控
					title: '道路监控1',
					videoUrl: 'https://rtmp02open.ys7.com:9188/v3/openlive/AG9618152_1_1.flv?expire=1737100100&id=669931433170571264&t=a93c870e7dbe10be44a5ee134dd1e2f84273eba9c74588b4a698d279937d7acc&ev=100',
					videoType: 1,
					type: 5,
					location: ''
				}, {
					//11号监控
					title: '道路监控2',
					videoUrl: 'https://open.ys7.com/v3/openlive/AG9618162_1_1.m3u8?expire=1737165686&id=670206519184297984&t=4261702e021846d56f0b454ee79444cb5fe07d83a0f335d1c88e5f2970776f1c&ev=100',
					videoType: 2,
					type: 5,
					location: ''
				}, {
					//12号监控
					title: '道路监控3',
					videoUrl: 'https://open.ys7.com/v3/openlive/AG6270426_1_1.m3u8?expire=1737165748&id=670206780969586688&t=9f09e592215fb2942fcdd60742af97425649f37a159def1af61ee26683ddee07&ev=100',
					videoType: 2,
					type: 5,
					location: ''
				}, {
					//13号监控
					title: '道路监控4',
					videoUrl: 'https://open.ys7.com/v3/openlive/AG9618100_1_1.m3u8?expire=1737165764&id=670206847125590016&t=cb140ef0ef0ea5cdfb1adfbd431ca7f3ca9187039465d985d71254b465e20c54&ev=100',
					videoType: 2,
					type: 5,
					location: ''
				}, {
					//14号监控
					title: '道路监控5',
					videoUrl: 'https://open.ys7.com/v3/openlive/AG6270313_1_1.m3u8?expire=1737165774&id=670206889040019456&t=1bf47400335e2a3e071107cf3b7a0b0e903e79ea00f1a0533563336a00c6e228&ev=100',
					videoType: 2,
					type: 5,
					location: ''
				}, {
					//9号监控
					title: '地灾监控1',
					videoUrl: 'https://rtmp02open.ys7.com:9188/v3/openlive/AG9618152_1_1.flv?expire=1737100100&id=669931433170571264&t=a93c870e7dbe10be44a5ee134dd1e2f84273eba9c74588b4a698d279937d7acc&ev=100',
					videoType: 1,
					type: 6,
					location: ''
				}, {
					//11号监控
					title: '地灾监控2',
					videoUrl: 'https://open.ys7.com/v3/openlive/AG9618162_1_1.m3u8?expire=1737165686&id=670206519184297984&t=4261702e021846d56f0b454ee79444cb5fe07d83a0f335d1c88e5f2970776f1c&ev=100',
					videoType: 2,
					type: 6,
					location: ''
				}, {
					//12号监控
					title: '地灾监控3',
					videoUrl: 'https://open.ys7.com/v3/openlive/AG6270426_1_1.m3u8?expire=1737165748&id=670206780969586688&t=9f09e592215fb2942fcdd60742af97425649f37a159def1af61ee26683ddee07&ev=100',
					videoType: 2,
					type: 6,
					location: ''
				}, {
					//13号监控
					title: '地灾监控4',
					videoUrl: 'https://open.ys7.com/v3/openlive/AG9618100_1_1.m3u8?expire=1737165764&id=670206847125590016&t=cb140ef0ef0ea5cdfb1adfbd431ca7f3ca9187039465d985d71254b465e20c54&ev=100',
					videoType: 2,
					type: 6,
					location: ''
				}, {
					//14号监控
					title: '地灾监控5',
					videoUrl: 'https://open.ys7.com/v3/openlive/AG6270313_1_1.m3u8?expire=1737165774&id=670206889040019456&t=1bf47400335e2a3e071107cf3b7a0b0e903e79ea00f1a0533563336a00c6e228&ev=100',
					videoType: 2,
					type: 6,
					location: ''
				}]
			}
		},
		mounted() {
			if (!sessionStorage.getItem('log')) {
				this.$message.error('请先登录');
				this.$router.replace('/')
			}
		},
		methods: {
			rankBtn(e) {
				const path = e.path || (e.composedPath && e.composedPath())
				let target = path.filter((r) => /jk-list/.test(r.className))
				if (target.length) target = target[0]
				else return
				this.showVideo(JSON.parse(target.getAttribute('data')))
			},
			generateLicensePlateWithoutProvince() {
				const numbers = '0123456789ABCDEFGHJKLMNPQRSTUVWXYZ012345678901234567890123456789'; // 车牌号数字部分
				// 随机生成5位车牌号
				let plate = '';
				for (let i = 0; i < 4; i++) {
					// 前两位是字母
					if (i < 1) {
						plate += numbers[Math.floor(Math.random() * numbers.length)];
					}
					// 后三位是数字
					else {
						plate += numbers[Math.floor(Math.random() * numbers.length)];
					}
				}
				return plate;
			},
			showVideo(e) {
				this.qpShow = true
				setTimeout(function() {
					var config = {}
					if (e.videoType == 1) {
						config = {
							"id": "mse",
							"url": e.videoUrl,
							"plugins": [],
							"autoplay": true,
							"width": "200",
							"height": "200"
						}
						config.plugins.push(FlvPlugin)
					} else {
						config = {
							"id": "mse",
							"url": e.videoUrl,
							"playsinline": true,
							"plugins": [],
							"autoplay": true,
							"width": "200",
							"height": "200"
						}
						config.plugins.push(HlsPlugin)
					}

					let player = new Player(config)
				}, 200);

			},
			changeMenuIndex(e) {
				if (this.menuIndex == e) {
					this.menuIndex = 0
					this.$refs.map.initLocation(e)
				} else {
					this.menuIndex = 0
					let that = this
					setTimeout(function() {
						that.menuIndex = e
					}, 10);

					this.$refs.map.changeLocation(e)
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.yc {
		width: 44px;
		height: 28px;
		background: #F73F3F;
		border-radius: 4px 4px 4px 4px;

		font-weight: 400;
		font-size: 14px;
		color: rgba(255, 255, 255, 0.87);
		line-height: 28px;
		text-align: center;
	}

	.zc {
		width: 44px;
		height: 28px;
		background: rgba(24, 144, 255, 0.2);
		border-radius: 4px 4px 4px 4px;
		border: 1px solid #1890FF;

		font-weight: 400;
		font-size: 14px;
		color: rgba(255, 255, 255, 0.87);
		line-height: 28px;
		text-align: center;
	}

	.close {
		position: fixed;
		right: 100px;
		top: 100px;
		width: 70px;
		height: 70px;
		z-index: 99999999999999;
		cursor: pointer;
	}

	.qp {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.6);
		z-index: 99999999999999;
	}

	.jk-add {
		font-size: 14px;
		font-weight: 400;
		color: #FFFFFF;
		margin-left: 20px;
	}

	.jk-list {
		width: 455px;
		height: 50px;
		background: rgba(0, 0, 0, 0.2);
		border-radius: 0px 0px 0px 0px;
		margin-top: 10px;
		padding: 10px 0 10px 20px;
		cursor: pointer;
	}

	.fram1-content {
		width: 500px;
		background: RGBA(29, 41, 68, 0.4);
		border-radius: 0px 0px 0px 0px;
		margin-top: 3.4vh;
		padding: 30px 0;
	}

	.jk-desc {
		font-size: 14px;
		font-family: Source Han Sans, Source Han Sans;
		font-weight: 400;
		color: #FFFFFF;
		margin-top: 16px;
	}

	.jk-num {
		font-size: 30px;
		font-family: D-DIN, D-DIN;
		font-weight: 700;
		color: #FFFFFF;
	}

	.jk-title {
		font-size: 14px;
		font-family: Source Han Sans, Source Han Sans;
		font-weight: 400;
		color: #FFFFFF;
	}

	.gzh {
		font-weight: 400;
		font-size: 20px;
		color: #FFFFFF;
		line-height: 40px;
		text-align: center;
	}

	.contt {
		width: 90%;
		font-weight: 400;
		font-size: 16px;
		color: #FFFFFF;
		text-indent: 2ch;
		margin: 1vh 0;
	}

	.title {
		font-weight: 700;
		font-size: 20px;
		color: #FFFFFF;
		line-height: 30px;
	}

	.zzh-mu {
		font-weight: 400;
		font-size: 16px;
		color: rgba(255, 255, 255, 0.87);
	}

	.zzh-title2 {
		width: 400px;
		height: 41px;
		// background-color: #15203E;
		font-size: 13px;
		font-family: Source Han Sans, Source Han Sans;
		font-weight: 400;
		color: #6FBAFF;
		padding: 0 30px;
	}

	.zzh-title {
		width: 400px;
		height: 41px;
		background-color: #094573;
		font-size: 15px;
		font-family: Source Han Sans, Source Han Sans;
		font-weight: 400;
		color: #6FBAFF;
		line-height: 41px;
		padding: 0 30px;
	}

	.zzh-dh {
		margin-left: 15px;
		font-size: 16px;
		font-family: Source Han Sans, Source Han Sans;
		font-weight: 400;
		color: rgba(255, 255, 255, 0.87);
	}

	.zzh-name {
		font-size: 14px;
		font-family: Source Han Sans, Source Han Sans;
		font-weight: 400;
		color: rgba(255, 255, 255, 0.87);
	}

	.zzh-content2 {
		width: 100%;
		padding: 0 30px;
		height: 80%;
		overflow: hidden;
	}

	.zzh-content {
		width: 400px;
		padding: 0 30px;
		height: 86%;
		overflow: hidden;
	}

	.warp2 {
		width: 90%;
		overflow: hidden;
		background: rgba(12, 18, 83, 0.7);

		.item {}

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			width: 100%;
			// background-color: rgba(255, 255, 255, 0.1);
			background: rgba(12, 18, 83, 0.7);

			li {
				cursor: pointer;
				background: rgba(12, 18, 83, 0.7);
				margin: 10px 0;
			}

			li:hover {
				// background-color: rgba(1, 168, 255, 1);
			}

		}
	}

	.warp {
		width: 125%;
		overflow: hidden;
		flex: 1;

		.item {}

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			width: 100%;
			// background-color: rgba(255, 255, 255, 0.1);

			li {
				cursor: pointer;
				background: rgba(12, 18, 83, 0.7);
				margin: 20px 0;
				padding: 10px;
			}

			li:hover {
				// background-color: rgba(1, 168, 255, 1);
			}

		}
	}

	.menu {
		width: 266px;
		height: 65px;
		cursor: pointer;
		margin-left: 10px;

	}

	.botto {
		width: 100%;
		position: fixed;
		bottom: 20px;
	}
</style>