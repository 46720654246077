<template>
	<div class="content">
		<div class="flex alit justb" style="height: 100%;width: 100%;">
			<div class="flex" style="cursor: pointer;margin-top: 140px;">
				<div class="" style="position: relative;">
					<div class="info-content" v-if="showInfo == 1">
						<div class="tc">
							<div class="cont">
								<div class="flex justb">
									<div class=""></div>
									<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E4%B8%89%E6%B2%B1%E6%A6%82%E8%BF%B0home.jpg"
										alt=""
										style="width: 320px;height: 185px;margin-right: 6px;margin-top: 10px;border-radius: 10px;" />
								</div>
								<div class="cont-title">
									三沱概述
								</div>
							</div>
						</div>
					</div>
					<div class="flexCol alit" style="margin: 0 120px 0 170px;" @mouseenter="showInfo = 1"
						@mouseleave="showInfo = 0" @click="goPage('/summary')">
						<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/home/%E7%BB%84%2012604%402x%281%29.png"
							style="width: 140px;height: 170px;" alt="" />
						<div class="title">三沱概述</div>
					</div>
				</div>
				<div class="" style="position: relative;">
					<div class="info-content" style="left: 80px;" v-if="showInfo == 2">
						<div class="tc">
							<div class="cont">
								<div class="flex justb">
									<div class=""></div>
									<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E6%95%B0%E5%AD%97%E5%85%9A%E5%BB%BAhome.jpg"
										alt=""
										style="width: 320px;height: 185px;margin-right: 6px;margin-top: 10px;border-radius: 10px;" />
								</div>
								<div class="cont-title">
									数字党建
								</div>
							</div>
						</div>
					</div>
					<div class="flexCol alit" style="" @mouseenter="showInfo = 2" @mouseleave="showInfo = 0"
						@click="goPage('/partyBuilding')">
						<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/home/%E7%BB%84%2012604%402x%283%29.png"
							style="width: 140px;height: 170px;" alt="" />
						<div class="title">数字党建</div>
					</div>
				</div>
			</div>
			<div class="flex" style="cursor: pointer;margin-top: 140px;">
				<div class="" style="position: relative;transform: scaleX(-1)">
					<div class="info-content" style="left: 80px;" v-if="showInfo == 3">
						<div class="tc">
							<div class="cont" style="">
								<div class="flex justb" style="">
									<div class=""></div>
									<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E6%95%B0%E5%AD%97%E5%86%9C%E6%97%85home.jpg"
										alt=""
										style="width: 320px;height: 185px;margin-right: 6px;margin-top: 10px;border-radius: 10px;transform: scaleX(-1)" />
								</div>
								<div class="cont-title" style="transform: scaleX(-1)">
									数字农旅
								</div>
							</div>
						</div>
					</div>
					<div class="flexCol alit" @mouseenter="showInfo = 3" @mouseleave="showInfo = 0"
						@click="goPage('/tourism')">
						<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/home/%E7%BB%84%2012604%402x%282%29.png"
							style="width: 140px;height: 170px;" alt="" />
						<div class="title" style="transform: scaleX(-1)">数字农旅</div>
					</div>
				</div>
				<div class="" style="position: relative;">
					<div class="info-content" style="left: -240px;transform: scaleX(-1)" v-if="showInfo == 4">
						<div class="tc">
							<div class="cont">
								<div class="flex justb">
									<div class=""></div>
									<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E6%95%B0%E5%AD%97%E7%9B%91%E7%AE%A1home.jpg"
										alt=""
										style="width: 320px;height: 185px;margin-right: 6px;margin-top: 10px;border-radius: 10px;transform: scaleX(-1)" />
								</div>
								<div class="cont-title" style="transform: scaleX(-1)">
									数字监管
								</div>
							</div>
						</div>
					</div>
					<div class="flexCol alit" style="margin: 0 170px 0 120px;" @mouseenter="showInfo = 4"
						@mouseleave="showInfo = 0" @click="goPage('/supervise')">
						<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/home/%E7%BB%84%2012604%402x.png"
							style="width: 140px;height: 170px;" alt="" />
						<div class="title">数字监管</div>
					</div>
				</div>
			</div>


		</div>
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {
				showInfo: 0
			}
		},
		mounted() {
			if (!sessionStorage.getItem('log')) {
				this.$message.error('请先登录');
				this.$router.replace('/')
			}
		},
		methods: {
			goPage(e) {
				this.$router.push(e)
			}
		}
	}
</script>

<style>
	.info-content {
		width: 414px;
		height: 300px;
		overflow: hidden;
		position: absolute;
		top: -300px;
		left: 250px;
	}

	.cont-title {
		font-weight: 400;
		font-size: 24px;
		color: #AFD5FF;
		text-align: center;
		margin-left: 90px;
		margin-top: 32px;
	}

	.cont {
		width: 414px;
		height: 300px;
	}

	@keyframes slideInFromLeft {
		0% {
			width: 0;
		}

		100% {
			width: 414px;
		}
	}

	.tc {
		width: 414px;
		height: 300px;
		background-size: 100% 100%;
		background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/home/%E7%BB%84%2012738%402x.png');

		animation: slideInFromLeft 0.5s ease-out forwards;
		overflow: hidden;
	}

	.tc-out {
		width: 0px;
		height: 300px;
		background-size: 100% 100%;
		background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/home/%E7%BB%84%2012738%402x.png');

		animation: slideInFromOut 0.5s ease-out forwards;
		overflow: hidden;
	}

	@keyframes slideInFromOut {
		0% {
			width: 300px;
		}

		100% {
			width: 0;
		}
	}

	.content {
		background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/home/%E7%BB%84%2012587%402x%20%284%29.png');
		background-size: 100% 100%;
		position: fixed;
		top: 0;
		height: 100%;
		width: 100%;
	}

	.title {
		font-family: YouSheBiaoTiHei, YouSheBiaoTiHei;
		font-weight: 900;
		font-size: 32px;
		color: #C8E2FF;
		text-shadow: 0px 2px 8px #1900FF;
		margin-top: 6px;
	}
</style>