<template>
	<div class="">
		<santuoMap style="height: 100%;width: 100%;" ref="map" :peopleList="peopleList"></santuoMap>
		<div class="botto flex justc alit">
			<img :src="menuIndex == 1 ? 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E5%85%9A%E5%BB%BA%E7%90%86%E8%AE%BA2%402x.png' : 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E5%85%9A%E5%BB%BA%E7%90%86%E8%AE%BA1%402x.png'"
				class="menu" alt="" @click="changeMenuIndex(1)" />
			<img :src="menuIndex == 2 ? 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E5%AE%B9%E5%99%A8%402x%281%29.png' : 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E7%BB%84%2012627%402x%281%29.png'"
				class="menu" alt="" @click="changeMenuIndex(2)" />
			<img :src="menuIndex == 3 ? 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E4%B8%89%E6%B2%B1%E5%85%AD%E5%88%B6-check.png' : 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E4%B8%89%E6%B2%B1%E5%85%AD%E5%88%B6-defalut.png'"
				class="menu" alt="" @click="changeMenuIndex(3)" />
			<img :src="menuIndex == 4 ? 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E5%AE%B9%E5%99%A8%402x%283%29.png' : 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E7%BB%84%2012629%402x.png'"
				class="menu" alt="" @click="changeMenuIndex(4)" />
		</div>

		<div class="popup-info" v-if="menuIndex == 1 || menuIndex == 2 ||menuIndex == 3" style="overflow: hidden;">
			<img src="../../static/close2.png" class="popup-close" style="width: 30px;height: 30px;" alt=""
				@click="changeMenuIndex(menuIndex)" v-if="menuIndex != 5 " />
			<div class="flexCol alit" v-if="menuIndex == 1">

				<div class="info-title flex alit" style="margin: 5% 0 5% 0;">
					<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/image%203%402x.png"
						style="width: 24px;height: 24px;margin-right: 7px;" alt="" />
					党建理论
				</div>
				<div class="popup-list" @click="rankBtn($event)">
					<vue-seamless-scroll :data="fileList" class="warp3 flexCol alit" :class-option="classOption">
						<ul class="item flexCol alit">
							<li v-for="(item, index) in fileList" :key="index" :data="JSON.stringify(item)"
								class="listItem">
								<div class="flex justb alit">
									<div class="" style="width: 23vw;color: #fff;font-size: 13px;">
										{{item.title}}
									</div>
									<div class="flexCol"
										style="color: #fff;font-size: 13px;width:26px;margin-left: 5px;">
										{{item.type == 2 ? '视频' : '文章'}}
									</div>
								</div>
							</li>
						</ul>
					</vue-seamless-scroll>
				</div>

				<!-- <img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/%E7%9F%A9%E5%BD%A2%201397%20%281%29.png"
					style="width: 475px;margin-bottom:10px;" alt="" />
				<div class="dj-cont" style="margin-top: 5%;">
					党的十二大精神，是党的十二届六中全会召开以来，党中央作出的一系列重大政治决定，是我们党从新的历史起点出发，进行全面建设的纲领性文件，是我们党进行社会主义现代化建设的行动指南。要深入贯彻党的十二大精神，首先要从思想上树立起来，在思想上全面把握党的十二大精神，把它们融入自己的思想体系中，把它们变成自己的思想和行动的指导原则，把它们贯彻到日常的学习、工作和生活中去。
				</div>

				<div class="dj-cont">
					其次，要从行动上落实起来，结合实际，把党的十二大精神落实到各级党组织和党员的日常工作实践中，把它们贯彻到各级党组织和党员的思想和行动中去，落实到党的组织生活和群众工作中去。
				</div>
				<div class="dj-cont">
					再者，要从结果上把握起来，把党的十二大精神贯彻到社会主义现代化建设和改革发展中，把它们贯彻到党的建设和国家的发展中去，把它们贯彻到党的领导和人民的利益中去，把它们贯彻到改革开放和社会主义现代化建设中去，把它们贯彻到全党全社会的各项事业中去，使之成为一切活动的引领和指导。
				</div> -->
			</div>

			<div class="flexCol alit" style="height: 80vh;" v-if="menuIndex == 2">

				<div class="info-title flex alit" style="margin: 3vh 0 3vh 0;">
					<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/image%203%402x.png"
						style="width: 24px;height: 24px;margin-right: 7px;" alt="" />
					党组织架构
				</div>

				<div class="flexCol alit">
					<div class="flex">
						<div class="flex alit">
							<img :src="peopleList[0].image" style="width: 60px;height: 80px;margin-right: 6px;"
								alt="" />
							<div class="">
								<div class="peo-top-name">{{peopleList[0].name}}</div>
								<div class="peo-top-desc2">{{peopleList[0].job}}</div>
							</div>
						</div>
						<div class="flex alit" style="margin-left: 14px;">
							<img :src="peopleList[1].image" style="width: 60px;height: 80px;margin-right: 6px;"
								alt="" />
							<div class="">
								<div class="peo-top-name">{{peopleList[1].name}}</div>
								<div class="peo-top-desc2">{{peopleList[1].job}}</div>
							</div>
						</div>
					</div>
					<div class="flex" style="margin-top: 2vh;">
						<div class="flex alit">
							<img :src="peopleList[2].image" style="width: 60px;height: 80px;margin-right: 6px;"
								alt="" />
							<div class="">
								<div class="peo-top-name">{{peopleList[2].name}}</div>
								<div class="peo-top-desc2">{{peopleList[2].job}}</div>
							</div>
						</div>
						<div class="flex alit" style="margin-left: 14px;">
							<img :src="peopleList[3].image" style="width: 60px;height: 80px;margin-right: 6px;"
								alt="" />
							<div class="">
								<div class="peo-top-name">{{peopleList[3].name}}</div>
								<div class="peo-top-desc2">{{peopleList[3].job}}</div>
							</div>
						</div>
						<div class="flex alit" style="margin-left: 14px;">
							<img :src="peopleList[4].image" style="width: 60px;height: 80px;margin-right: 6px;"
								alt="" />
							<div class="">
								<div class="peo-top-name">{{peopleList[4].name}}</div>
								<div class="peo-top-desc2">{{peopleList[4].job}}</div>
							</div>
						</div>
					</div>
					<div class="count-people flex justc alit" style="margin-top: 3vh;">
						<div class="flex alit">
							<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/%E5%AE%B9%E5%99%A8%20333%402x.png"
								style="width: 21px;height: 21px;" alt="" />
							<div class="people-count">党员 24人</div>
						</div>
						<div class="flex alit" style="margin-left: 40px;">
							<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/%E5%AE%B9%E5%99%A8%20333%402x%281%29.png"
								style="width: 21px;height: 21px;" alt="" />
							<div class="people-count">村民代表 85人</div>
						</div>
					</div>
				</div>
				<div class="info-title flex alit" style="margin: 2vh 0 1vh 0;">
					<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/image%203%402x.png"
						style="width: 24px;height: 24px;margin-right: 7px;" alt="" />
					优秀党员风采
				</div>
				<vue-seamless-scroll :data="peopleList" class="warp" :class-option="classOption">
					<ul class="item">
						<li v-for="(item, index) in peopleList" :key="index" class="flex justb listItem"
							:data="JSON.stringify(item)">
							<div class="flex" style="width: 100%;padding-top: 20px;">
								<img :src="item.image" style="width: 80px;height: 100px;margin-right: 7px;" alt="" />
								<div class="flexCol" style="flex: 1;">
									<div style="color: #fff;font-size: 14px;margin-top: 5px;" v-text="item.name"></div>
									<div style="color: #A3A7AD;font-size: 11px;margin: 2px 0 10px 0;" v-text="item.job">
									</div>
									<div style="color: #fff;font-size: 13px;" v-text="item.desc"></div>
								</div>
							</div>
						</li>
					</ul>
				</vue-seamless-scroll>
				<div class="" style="height: 10px;margin-top: 10px;"></div>
			</div>
			<div class="flexCol alit" v-if="menuIndex == 3" style="height: 98%;overflow: hidden;">
				<div class="info-title flex alit" style="margin: 3vh 0 1vh 0;">
					<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/image%203%402x.png"
						style="width: 24px;height: 24px;margin-right: 7px;" alt="" />
					三沱“六制”工作法
				</div>
				<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/%E5%AE%B9%E5%99%A8%20418.png"
					style="width: 480px;" alt="" @click="changeMenuIndex(6)" />
				<div class="info-title flex alit" style="margin: 4vh 0 3vh 0;">
					<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/image%203%402x.png"
						style="width: 24px;height: 24px;margin-right: 7px;" alt="" />
					三沱“五好”村规
				</div>
				<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/%E5%AE%B9%E5%99%A8%20327.png"
					style="width: 480px;" alt="" @click="changeMenuIndex(7)" />
				<!-- <div class="info-title flex alit" style="margin: 2vh 0 2vh 0;">
					<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/image%203%402x.png"
						style="width: 24px;height: 24px;margin-right: 7px;" alt="" />
					三问三答群众会
				</div>
				<div class="" style="height: 20vh;">
					<div class="flex" v-for="item in 2" style="margin-bottom: 3vh;">
						<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/%E7%9F%A9%E5%BD%A2%201397%20%281%29.png"
							style="width: 100px;height: 100px;margin-right: 14px;" alt="" />
						<div class="flexCol">
							<div class="tit1">三沱村给党员同志培训道路安全知识</div>
							<div class="tit2">2024 / 06 / 10 15:28</div>
							<div class="tit3">
								走进安坪镇三沱村，一面面内容丰富、制作精美的文化墙让人眼前一亮。近年来，安坪镇以乡村振兴为抓手，结合三沱村实际，积极开展文化墙建设，助结合三沱村实际，积极开展文化墙建设结合三沱村…
							</div>
						</div>
					</div>
				</div> -->
			</div>
		</div>

		<div class="popup-info flexCol alit" v-if="menuIndex == 5 ">
			<img src="../../static/back.png" class="popup-back" style="width: 40px;height: 40px;" alt=""
				@click="changeMenuIndex(1)" />
			<div class="info-title flex alit justc" style="margin: 5% 0 0 0;width: 22vw;">
				<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/image%203%402x.png"
					style="width: 24px;height: 24px;margin-right: 7px;" alt="" />
				<div class="" style="">{{showContent.title}}</div>
			</div>
			<div class=""
				style="width: 85%;font-size: 14px;color: #fff;margin-top: 5px;overflow-y: scroll;height: 86%;white-space:pre-wrap;overflow-x: hidden;"
				v-if="newsType == 1" v-html="wordContent">
				<!-- {{showContent.content}} -->
			</div>
			<div class="flex alit justc" v-if="newsType == 2">
				<video ref="video"
					src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/home/ry/f4fb5e2ce595e36ff5b0f43e976a209c.mp4"
					style="width: 475px;" object-fit="cover" class="video" controls
					:show-center-play-btn="false"></video>
			</div>
		</div>

		<div class="popup-info flexCol alit" v-if="menuIndex == 6 ">
			<img src="../../static/back.png" class="popup-back" style="width: 40px;height: 40px;" alt=""
				@click="changeMenuIndex(3)" />
			<div class="info-title flex alit justc" style="margin: 5% 0 0 0;">
				<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/image%203%402x.png"
					style="width: 24px;height: 24px;margin-right: 7px;" alt="" />
				<div class="">三沱“六制”工作法</div>
			</div>

			<div class="">
				<vue-seamless-scroll :data="peopleList" class="warp2" style="width: 100%;margin-top: 10px;"
					:class-option="classOption">
					<ul class="item" style="width: 100%;">
						<li class="flexCol justb listItem" style="width: 100%;">
							<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/%E5%85%AD%E5%88%B6/1.png"
								style="width: 100%;" alt="" />
							<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/%E5%85%AD%E5%88%B6/2.png"
								style="width: 100%;" alt="" />
							<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/%E5%85%AD%E5%88%B6/3.png"
								style="width: 100%;" alt="" />
							<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/%E5%85%AD%E5%88%B6/4.png"
								style="width: 100%;" alt="" />
							<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/%E5%85%AD%E5%88%B6/5.png"
								style="width: 100%;" alt="" />
							<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/%E5%85%AD%E5%88%B6/6.png"
								style="width: 100%;" alt="" />
							<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/%E5%85%AD%E5%88%B6/7.png"
								style="width: 100%;" alt="" />
						</li>
					</ul>
				</vue-seamless-scroll>
			</div>

		</div>

		<div class="popup-info flexCol alit" v-if="menuIndex == 7 ">
			<img src="../../static/back.png" class="popup-back" style="width: 40px;height: 40px;" alt=""
				@click="changeMenuIndex(3)" />
			<div class="info-title flex alit justc" style="margin: 5% 0 3% 0;">
				<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/image%203%402x.png"
					style="width: 24px;height: 24px;margin-right: 7px;" alt="" />
				<div class="">三沱“五好”村规</div>
			</div>

			<div class="flexCol alit" style="height: 70vh;overflow-y: scroll;" v-if="menuIndex == 7 ">
				<div class="info-jj">
					三沱村位于奉节县长江南岸，属三峡库区移民村，幅员面积7.98平方公里，辖6个网格社，
					944户、2810人。2021年以来，三沱村坚持党建引领，创新“五好”村规民约（即开好会、管好水、扫好地、栽好花、做好人），引导群众有效提升和发挥自我管理、自我服务、自我教育效能，促进村风民风建设、村容村貌改观、特色产业发展，成为助力三沱村实现民富业兴，建设和美三沱的有效载体和有力举措。该村先后荣获全国乡村治理示范村、全国“一村一品”示范村、全国乡村特色产业超亿元村、中国美丽休闲乡村和重庆市脱贫攻坚先进集体、重庆市绿色示范村等20余项国家级及市级荣誉。
				</div>
				<img v-image-preview
					src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/%E5%85%AD%E5%88%B6/8.png"
					style="width: 100%;height: 32vh;" alt="" />
				<div class="info-jj">一、开好会，村事民事共商议</div>
				<div class="info-jj">
					以党建为引领，坚持群众路线，通过多类型会场讲政策、听民声、汇民意、解民忧，确保集体事务公开议、矛盾纠纷敞开议、发展规划共同议。一是“例行会”定村级事。实行“周一例会”机制，每周一由村“两委”班子、村民代表、驻村工作队召开工作例行会，复盘上周进度、部署本周工作，推动任务闭环落实。同时，结合“三会一课”制度、村社网格长会议，坚持“第一议题”学习制度，学政治要求、学政策规定，提高干部政治素养和政策水平。二是“议事会”解民生事。三沱村省道旁有一棵枝繁叶茂的黄葛树，村民们喜欢在树下唠家常、议事情，长此以往形成一个固定的村民议事点。“黄葛树下坐一坐，心头无疑惑”，三沱村大力推行“黄葛树下”议事会，凡是有关集体经济、重点项目、惠民补贴、邻里关系的事务，群众都习惯到黄葛树下“议一议”，进行集体讨论、共同商量、达成共识，并将议事点延伸到村民广场、门前院坝，做到把政策宣讲到家门口、把矛盾化解在家门口。三是“群众会”讲暖心事。推行“随时召开家庭会、每月召开院坝会、季度召开网格会、半年召开全村群众大会”模式，围绕群众获得感、集体荣誉感、群众满意度开展“三问三答”群众会活动，不断增强人民群众获得感、幸福感、安全感。
				</div>
				<div class="info-jj">二、管好水，协会自治立规矩</div>
				<div class="info-jj">
					为妥善解决山区农村“用水不便、管水不利”的难题，三沱村探索成立用水管护协会，利用协会有效经验，带动成立多元化群众协会，成为基层治理有效路径。一是集体用水管起来。过去，三沱村的生活用水问题是群众生活的痛点难点问题。为此，三沱村提档升级水利基础设施,
					建设智慧供水系统，采用“水厂+水池”双供水模式，成立“管得宽”用水管护协会，共同协商供水质量、水费价格，在村级层面设立管水员，负责水费收缴及运行管护，形成“家家都是协会会员，人人都是监督员”的用水格局，让村民用上“放心水”“实惠水”。二是协会模式用起来。坚持党组织领导下的村民自治制度，适应新时代下的村庄建设发展状况，参照用水管护协会发展模式，三沱村结合移风易俗整治工作、精神文明建设工作，特色产业发展工作，成立“理得顺”红白理事协会、“舞起来”文娱表演协会、“橙上网”电商管理协会，促进各协会形成“协会有人干事、干事有人成事”的工作格局。三是群众协会动起来。三沱村各类协会成立以来，让村庄精神风貌、产业发展焕然一新。“理得顺”红白理事协会积极开展红白喜事简办宣传劝导工作，有效减轻村民人情负担；“舞起来”文娱创办协会组建创新具有本村特色的腰鼓舞、坝坝舞等文艺活动，丰富村民休闲生活；“橙上网”电商管理协会依托电商直播中心，吸纳全村942户脐橙种植户加入，培养出“犟老头”“小叶家”“三沱橙”“派源脐橙”等本土网红及果业品牌。
				</div>
				<div class="info-jj">三、扫好地，屋舍院坝变有序</div>
				<div class="info-jj">
					屋舍院坝是农村生产生活的重要空间场所，三沱村以整洁屋舍院坝方寸之地为切口，推动实现村域人居环境不断提升，实现“生活场景+生产场域+生态建设”的多元蝶变。一是美化生活庭院。深入开展“美丽庭院”创建行动，建立清洁评比红黑榜，引入积分制对清洁卫生情况进行量化考评、定期公示，推动清洁家园成自觉、成美景。持续打造“美丽庭院”样板，成功构建樊家小院、脐橙小院、蔡家院子等特色小院20余户，形成从“一时美”到“持久美”，“一处美”到“处处美”的生动局面。二是升级生产空间。牢记绿水青山就是金山银山理念，依托“1+3+N”网格治理体系，盘活志愿者、公益性岗位力量，开展“每周区域打扫、季度全域清洁”志愿活动，全面升级改造沿线房屋立面、蓝顶棚，盘活利用沿江50户闲置房屋，拟升级打造农家乐15家、民宿30家、创意商铺5家，促进村庄形态与自然环境、发展业态相得益彰、相辅相成。三是建设生态农村。三沱围绕村沿线主干道路进行绿化、美化、亮化，提升“美在公路、留在橙香”全新体验，沿路布局基础设施类、产业发展类、文旅融合类、流域治理类项目，依托3.7公里沿江省道，布局1000亩脐橙采摘园、300亩5G智慧脐橙园，以试点示范促进发展，展现“江边一条四好路、路边一排江景房、房前一座加工厂、房后一圈果菜粮”的美好乡村场景。
				</div>
				<div class="info-jj">四、栽好花，村容村貌展新颜</div>
				<div class="info-jj">
					三沱村以“花”为媒，以绣“花”功夫点缀江村江景，以房屋点带公路线、以游览线带村庄面，好花好景成为展现村庄欣欣向荣面貌的重要窗口。一是扮靓房前屋后。三沱村以绿色发展引领农村人居环境整治为抓手，坚持以绿治乱、以绿治脏，鼓励引导群众利用庭院内外边角地、空闲地，建设小果园、小菜园、小花园，种植迎春花、三角梅、矮牵牛等花卉绿植，实现房前屋后“三季有花、四季常绿”。二是扮靓公路沿线。围绕基层党建、“三峡移民”精神、特色产业发展等主题，推出精品文旅线路，打造十里花果滨江廊道。沿村主干道布置村标小广场、1997移民纪念点、文化宣传墙、移民精神传承馆等观赏点，绘制1500余平米墙体水墨画，建设3.8公里的彩色自行车道，让村民和游客身临花境、身心愉悦。三是扮靓全村全域。乡村人居环境的提升，让乡村提“颜值”、添“气质”。三沱村积极举办三峡橙庄庭院园艺大赛、美丽庭院设计案例大赛等各类活动，让绿化促美化、绿化促文明的理念深入人心，提升村民生活品质，实现以个人“家庭小美”促进三沱“全域秀美”。
				</div>
				<div class="info-jj">五、做好人，村风民风蓄涵养</div>
				<div class="info-jj">
					三沱村以榜样带动激发群众自觉意识，以文化活动丰富群众精神生活，大力推动移风易俗，培育有涵养的村风民风。一是强化榜样示范引领。三沱村积极开展榜样选评活动，评选致富能手、最美乡贤、孝心儿媳、最美婆婆等“三沱榜样”，在党员群众、老干部、新乡贤群体中，择优聘任村级发展参谋员、招商引资宣传员、文明乡风引导员，以榜样带领身边人、以先进带动村庄发展，营造崇尚典型、见贤思齐的浓厚氛围。二是丰富精神文化活动。立足三沱、辐射全镇挖掘传统文化，以村社为单元，组建6支文艺活动队，自编自排打腰鼓、彩龙船、坝坝舞等节目，让文化有阵地、活动有意义、群众有欢乐。2022年，三沱村村民自发组织“喜迎二十大·奋进新征程”文艺汇演，展现群众歌颂党、歌颂新时代、歌颂美好生活的精神面貌。三是自觉摒弃遗风陋俗。以深入开展新时代文明实践活动为契机，通过标语上墙、广播宣传、群内推送等方式开展宣传教育活动，积极倡导全体党员、村民群众摒弃陋俗，养成和巩固讲文明、讲道德、讲卫生的良好生活习惯，倡导和践行红白喜事简办、家庭小事不办，营造树新风、破旧俗的浓厚氛围。
				</div>
				<div class="info-jj">
					三沱村创新实施“五好”村规民约，深化村民自治实践，拓宽群众有序参与基层治理渠道，保障人民依法管理基层公共事务，健全自治、法治、德治三治融合的乡村治理体系，增强群众融入感、获得感、满意度，推动精神文明建设、人居环境提升、集体产业发展等工作不断取得新进步，有效提升了乡村治理效能。
				</div>
			</div>
		</div>

		<div class="popup-info flexCol alit" v-if="menuIndex == 4 " style="height: 67vh;">
			<img src="../../static/close2.png" class="popup-close" style="width: 30px;height: 30px;" alt=""
				@click="changeMenuIndex(4)" />
			<div class="info-title flex alit" style="margin: 5% 0 -30px 0;">
				<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/image%203%402x.png"
					style="width: 24px;height: 24px;margin-right: 7px;" alt="" />
				试点示范
			</div>
			<div class="flex alit justc" style="flex-wrap: wrap;">
				<div class="flexCol alit" style="width: 30%;margin-top: 50px;cursor: pointer;"
					@click="openUrl('https://jdydt.ccdi.gov.cn/#/')">
					<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/%E7%BB%84%2012594%402x.png"
						style="width: 100px;height: 100px;" alt="" />
					<div class="sdsf">基层权力监督</div>
				</div>
				<div class="flexCol alit" style="width: 30%;margin-top: 50px;cursor: pointer;"
					@click="openUrl('https://www.12371.cn/')">
					<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/%E7%9F%A9%E5%BD%A2%201402%402x.png"
						style="width: 100px;height: 100px;" alt="" />
					<div class="sdsf">共产党员网</div>
				</div>
				<div class="flexCol alit" style="width: 30%;margin-top: 50px;cursor: pointer;"
					@click="openUrl('https://www.12371.gov.cn/')">
					<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/%E7%BB%84%2012593%402x.png"
						style="width: 100px;height: 100px;" alt="" />
					<div class="sdsf">七一网</div>
				</div>
				<div class="flexCol alit" style="width: 30%;margin-top: 50px;cursor: pointer;"
					@click="openUrl('http://www.npc.gov.cn/npc/index.html')">
					<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/%E7%BB%84%2012594%402x%281%29.png"
						style="width: 100px;height: 100px;" alt="" />
					<div class="sdsf">人民代表大会</div>
				</div>
				<div class="flexCol alit" style="width: 30%;margin-top: 50px;cursor: pointer;"
					@click="openUrl('http://www.cppcc.gov.cn/')">
					<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/%E7%BB%84%2012668.png"
						style="width: 100px;height: 100px;" alt="" />
					<div class="sdsf">中国政协</div>
				</div>
				<div class="flexCol alit" style="width: 30%;margin-top: 50px;cursor: pointer;"
					@click="openUrl('https://www.cupl148.cn/')">
					<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/%E7%BB%84%2012593%402x%281%29.png"
						style="width: 100px;height: 100px;" alt="" />
					<div class="sdsf">中国政法</div>
				</div>
				<div class="flexCol alit" style="width: 30%;margin-top: 50px;cursor: pointer;"
					@click="changeMenuIndex(8)">
					<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/%E6%B8%85%E5%BB%89%E6%9D%91%E5%B1%852.png"
						style="width: 100px;height: 100px;" alt="" />
					<div class="sdsf">清廉村居</div>
				</div>
				<div class="flexCol alit" style="width: 30%;margin-top: 50px;cursor: pointer;">

				</div>
				<div class="flexCol alit" style="width: 30%;margin-top: 50px;cursor: pointer;">

				</div>
			</div>

		</div>

		<div class="popup-info flexCol alit" v-if="menuIndex == 8 ">
			<img src="../../static/back.png" class="popup-back" style="width: 40px;height: 40px;" alt=""
				@click="changeMenuIndex(4)" />
			<div class="info-title flex alit justc" style="margin: 5% 0 3% 0;">
				<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/image%203%402x.png"
					style="width: 24px;height: 24px;margin-right: 7px;" alt="" />
				<div class="">清廉村居</div>
			</div>
			<div class="flexCol alit" style="height: 70vh;overflow-y: scroll;">
				<div class="info-jj">
					按“党建统领、监督五化”工作要求，（监督责任网格化、监督探头多样化、监督平台数智化、监督内容清单化、监督效能积分化），持续一体推进村（社区）干部“三不腐”机制建设，探索出“五亮五促”清廉村居“三沱路径”。
				</div>
				<img v-image-preview
					src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/%E5%9B%BE%E7%89%871.png"
					style="width: 90%;height: 32vh;" alt="" />
				<div class="info-jj">
					教育亮“规”促自觉，认真开展“三会一课”，在主题党日活动中开设“10分钟廉洁教育”小课堂，将廉洁教育融入日常、抓在经常，利用身边违法案例为“反面教材”，开展“以案四说”警示教育，不断提升廉洁自律意识。
				</div>
				<div class="info-jj">
					清单亮“责”促履职，聚焦服务清单、负面清单、责任清单“三张清单”，结合党务、村务、财务、事务、服务，形成36条村级权力清单，推行工作交办、催办、督办“三色”交办单，实行监察监督员“派单制”，实行周一例会，实施工作任务“清单化”管理，工作落实“动态化”销号。
				</div>
				<div class="info-jj">
					双线亮“权”促规范，依托中央纪委国家监委小微权力“监督一点通”和“奉节县小微权力监督平台”，做优公示公开“六个一”，“线上+线下”全过程公开村（居）务决策、财产管理、工程项目建设、惠农政策落实等情况。目前，已公开信息156条，项目6个。充分发挥自治协会作用，按照村（社区）两委会议、村（居）民自治组织商议、群众评议、监督委员会审议“四议”工作机制，引导群众在参与中监督，规范权力运行。
				</div>
				<div class="info-jj">
					积分亮“相”促担当，建立村干部廉洁档案6份，将重大事项报告、配偶及子女从业情况等9项信息纳入档案内容并动态更新。结合新时代文明实践实行“廉洁积分制”，按照6种加分、9种扣分情形，以“红、黄、蓝”进行廉洁风险等级管理。
				</div>
				<div class="info-jj">
					文化亮“廉”促新风，以清廉村居建设为契机，充分运用三峡移民精神文化中的“廉”元素，弘扬百万三峡移民舍己为公的奉献精神，切实将廉洁文化融入党员干部和群众生活。用好“黄葛树议事”品牌，提炼“五好”村规民约自治制度，充分调动群众积极性，积极制止群众参与“无事酒”、打牌赌博等歪风陋习，主动参与人居环境整治行动，打造了一批最美庭院，评选了一批最美群众。
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import santuoMap from '../components/partyBuildMap.vue'
	import partBuildData from '../components/partBuildData.js'
	import vueSeamlessScroll from 'vue-seamless-scroll'
	import mammoth from "mammoth"
	export default {
		components: {
			santuoMap,
			vueSeamlessScroll
		},
		data() {
			return {
				showContent: '',
				partBuildData: partBuildData,
				fileList: [{
					type: 1,
					title: '1.党的二十大报告',
					fileUrl: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/file/1.%E5%85%9A%E7%9A%84%E4%BA%8C%E5%8D%81%E5%A4%A7%E6%8A%A5%E5%91%8A.docx'
				}, {
					type: 1,
					title: '2.中国共产党章程',
					fileUrl: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/file/2.%E4%B8%AD%E5%9B%BD%E5%85%B1%E4%BA%A7%E5%85%9A%E7%AB%A0%E7%A8%8B.docx'
				}, {
					type: 1,
					title: '3.论党的自我革命-习近平（2023年3月）',
					fileUrl: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/file/3.%E8%AE%BA%E5%85%9A%E7%9A%84%E8%87%AA%E6%88%91%E9%9D%A9%E5%91%BD-%E4%B9%A0%E8%BF%91%E5%B9%B3%EF%BC%882023%E5%B9%B43%E6%9C%88%EF%BC%89.docx'
				}, {
					type: 1,
					title: '4.习近平新时代中国特色社会主义思想的世界观和方法论专题摘编（2023年3月）',
					fileUrl: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/file/4.%E4%B9%A0%E8%BF%91%E5%B9%B3%E6%96%B0%E6%97%B6%E4%BB%A3%E4%B8%AD%E5%9B%BD%E7%89%B9%E8%89%B2%E7%A4%BE%E4%BC%9A%E4%B8%BB%E4%B9%89%E6%80%9D%E6%83%B3%E7%9A%84%E4%B8%96%E7%95%8C%E8%A7%82%E5%92%8C%E6%96%B9%E6%B3%95%E8%AE%BA%E4%B8%93%E9%A2%98%E6%91%98%E7%BC%96%EF%BC%882023%E5%B9%B43%E6%9C%88%EF%BC%89.docx'
				}, {
					type: 1,
					title: '5.习近平关于调查研究论述摘编（2023年3月）',
					fileUrl: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/file/5.%E4%B9%A0%E8%BF%91%E5%B9%B3%E5%85%B3%E4%BA%8E%E8%B0%83%E6%9F%A5%E7%A0%94%E7%A9%B6%E8%AE%BA%E8%BF%B0%E6%91%98%E7%BC%96%EF%BC%882023%E5%B9%B43%E6%9C%88%EF%BC%89.docx'
				}, {
					type: 1,
					title: '深入学习贯彻习近平总书记关于主题教育系列重要讲话和重要指示批示精神',
					fileUrl: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/file/%E6%B7%B1%E5%85%A5%E5%AD%A6%E4%B9%A0%E8%B4%AF%E5%BD%BB%E4%B9%A0%E8%BF%91%E5%B9%B3%E6%80%BB%E4%B9%A6%E8%AE%B0%E5%85%B3%E4%BA%8E%E4%B8%BB%E9%A2%98%E6%95%99%E8%82%B2%E7%B3%BB%E5%88%97%E9%87%8D%E8%A6%81%E8%AE%B2%E8%AF%9D%E5%92%8C%E9%87%8D%E8%A6%81%E6%8C%87%E7%A4%BA%E6%89%B9%E7%A4%BA%E7%B2%BE%E7%A5%9E.docx'
				}, {
					type: 1,
					title: '习近平新时代中国特色社会主义思想学习纲要(2023年版)',
					fileUrl: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/file/%E4%B9%A0%E8%BF%91%E5%B9%B3%E6%96%B0%E6%97%B6%E4%BB%A3%E4%B8%AD%E5%9B%BD%E7%89%B9%E8%89%B2%E7%A4%BE%E4%BC%9A%E4%B8%BB%E4%B9%89%E6%80%9D%E6%83%B3%E5%AD%A6%E4%B9%A0%E7%BA%B2%E8%A6%81%282023%E5%B9%B4%E7%89%88%29.docx'
				}, {
					type: 1,
					title: '习近平新时代中国特色社会主义思想专题摘编（2023年3月）',
					fileUrl: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/file/%E4%B9%A0%E8%BF%91%E5%B9%B3%E6%96%B0%E6%97%B6%E4%BB%A3%E4%B8%AD%E5%9B%BD%E7%89%B9%E8%89%B2%E7%A4%BE%E4%BC%9A%E4%B8%BB%E4%B9%89%E6%80%9D%E6%83%B3%E4%B8%93%E9%A2%98%E6%91%98%E7%BC%96%EF%BC%882023%E5%B9%B43%E6%9C%88%EF%BC%89.docx'
				}, {
					type: 1,
					title: '习近平著作选读第二卷(2023年4月）',
					fileUrl: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/file/%E4%B9%A0%E8%BF%91%E5%B9%B3%E8%91%97%E4%BD%9C%E9%80%89%E8%AF%BB%E7%AC%AC%E4%BA%8C%E5%8D%B7%282023%E5%B9%B44%E6%9C%88%EF%BC%89.docx'
				}, {
					type: 1,
					title: '习近平著作选读第一卷（2023年4月）',
					fileUrl: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/file/%E4%B9%A0%E8%BF%91%E5%B9%B3%E8%91%97%E4%BD%9C%E9%80%89%E8%AF%BB%E7%AC%AC%E4%B8%80%E5%8D%B7%EF%BC%882023%E5%B9%B44%E6%9C%88%EF%BC%89.docx'
				}, {
					type: 1,
					title: '县委主题教育第一批总结暨第二批部署会议召开',
					fileUrl: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/file/%E5%8E%BF%E5%A7%94%E4%B8%BB%E9%A2%98%E6%95%99%E8%82%B2%E7%AC%AC%E4%B8%80%E6%89%B9%E6%80%BB%E7%BB%93%E6%9A%A8%E7%AC%AC%E4%BA%8C%E6%89%B9%E9%83%A8%E7%BD%B2%E4%BC%9A%E8%AE%AE%E5%8F%AC%E5%BC%80.docx'
				}, {
					type: 1,
					title: '中央主题教育第一批总结暨第二批部署会议召开',
					fileUrl: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/file/%E4%B8%AD%E5%A4%AE%E4%B8%BB%E9%A2%98%E6%95%99%E8%82%B2%E7%AC%AC%E4%B8%80%E6%89%B9%E6%80%BB%E7%BB%93%E6%9A%A8%E7%AC%AC%E4%BA%8C%E6%89%B9%E9%83%A8%E7%BD%B2%E4%BC%9A%E8%AE%AE%E5%8F%AC%E5%BC%80.doc'
				}, {
					type: 1,
					title: '重庆市主题教育第一批总结暨第二批部署会议召开(1)',
					fileUrl: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/file/%E9%87%8D%E5%BA%86%E5%B8%82%E4%B8%BB%E9%A2%98%E6%95%99%E8%82%B2%E7%AC%AC%E4%B8%80%E6%89%B9%E6%80%BB%E7%BB%93%E6%9A%A8%E7%AC%AC%E4%BA%8C%E6%89%B9%E9%83%A8%E7%BD%B2%E4%BC%9A%E8%AE%AE%E5%8F%AC%E5%BC%80%281%29.docx'
				}],
				newsType: 1,
				menuIndex: 0,
				wordContent: '',
				classOption: {
					limitMoveNum: 7,
					hoverStop: true,
					waitTime: 3000,
					step: 0.2
				},
				peopleList: [{
						name: '叶成路',
						job: '驻村第一书记',
						image: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/dy/%E5%8F%B6%E6%88%90%E8%B7%AF.jpg',
						desc: '处事惟公 做人惟实'
					}, {
						name: '黄明',
						job: '支部书记',
						image: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/dy/%E9%BB%84%E6%98%8E.jpg',
						desc: '客观、公正、康洁、自律'
					},
					{
						name: '李胜龙',
						job: '支部副书记',
						image: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/dy/%E6%9D%8E%E8%83%9C%E9%BE%99.jpg',
						desc: '勤奋务实，热心真诚'
					},
					{
						name: '余巧玲',
						job: '综合服务专干',
						image: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/dy/%E4%BD%99%E5%B7%A7%E7%8E%B2.jpg',
						desc: '热心服务，真诚待人'
					},
					{
						name: '刘阳',
						job: '综合综治专干',
						image: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/dy/%E5%88%98%E9%98%B3.jpg',
						desc: '换位思考，真心待人'
					},
					{
						name: '张平',
						job: '本土人才',
						image: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/dy/%E5%BC%A0%E5%B9%B3.jpg',
						desc: '勤奋做事，正派做人'
					},
					{
						name: '张学芳',
						job: '妇联主席',
						image: 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/partyBuild/dy/%E5%BC%A0%E5%AD%A6%E8%8A%B3.jpg',
						desc: '细心做事，用心服务'
					}
				],
				dataList: [{
						titile: '网格制',
						content: '统筹N大员，1+3+N分社包干，网格社负责一片、管理一片、服务一片。常态化推行干部走访、教师家访、医生巡访、农技随访”四访“工作，实现人在网中走、事在格中办。'
					},
					{
						titile: '议事制',
						content: '打造“黄葛树议事”品牌，规范“四议”议事程序，召开“三问三答”群众会，成立自治协会组织，做到群众的事群众议、群众的事群众定、群众的事群众办。'
					},
					{
						titile: '清单制',
						content: '制定村干部责任清单、村级权力清单36条、办事负面清单十严禁，运行“白黄红”三色交办单，推行纪委派单。'
					},
					{
						titile: '积分制',
						content: '村文明实践站，组织志愿者队伍，开办积分银行，推行“五好”行动，引导群众“大事靠国家、小事靠大家，公事一起干、私事自己干”。'
					},
					{
						titile: '监督制',
						content: '用户情牌、广播村村响、便民政策挂历、电视公开、两回两讲两解视频宣讲、小微权力监督平台，以公开促公正，以公正助公平，以公平树公信。'
					},
					{
						titile: '赛马制',
						content: '围绕“七张高分报表”“八张问题清单”，找准自己的工作抓手，以点带面，以一新带百新，推动工作出进度、出形象、出成效。'
					}
				]
			}
		},
		mounted() {
			if (!sessionStorage.getItem('log')) {
				this.$message.error('请先登录');
				this.$router.replace('/')
			}
		},
		methods: {
			readWordFile(file) {
				const reader = new FileReader();
				reader.onload = (e) => {
					const arrayBuffer = e.target.result;
					mammoth.convertToHtml({
							arrayBuffer: arrayBuffer
						})
						.then(result => {
							this.wordContent = result.value;
						})
						.catch(err => console.error(err));
				};
				reader.readAsArrayBuffer(file);
			},
			openUrl(url) {
				window.open(url)
			},
			rankBtn(e) {
				const path = e.path || (e.composedPath && e.composedPath())
				let target = path.filter((r) => /listItem/.test(r.className))
				if (target.length) target = target[0]
				else return
				console.log(JSON.parse(target.getAttribute('data')));
				// this.showVideo(JSON.parse(target.getAttribute('data')))
				let da = JSON.parse(target.getAttribute('data'))
				console.log(da);
				if (da.type == 1) {
					this.$requestFile(da.fileUrl).then(file => {
						const reader = new FileReader();
						reader.onload = (e) => {
							const arrayBuffer = e.target.result;
							mammoth.convertToHtml({
									arrayBuffer: arrayBuffer
								})
								.then(result => {
									this.wordContent = result.value;
									this.newsType = 1
								})
								.catch(err => console.error(err));
						};
						reader.readAsArrayBuffer(file);
					})
					//文本
					this.showContent = da


				} else {
					//视频
					this.newsType = 2
				}
				this.menuIndex = 5
			},
			changeMenuIndex(e) {
				let that = this
				if (this.menuIndex == e) {
					this.menuIndex = 0
					this.$refs.map.initLocation()
				} else {
					this.menuIndex = 0
					setTimeout(function() {
						that.menuIndex = e
						that.$refs.map.changeLocation()
					}, 10);

				}
			}
		}
	}
</script>

<style lang="scss">
	.info-jj {
		width: 475px;
		font-weight: 400;
		font-size: 12px;
		color: #FFFFFF;
		text-indent: 2ch;
		line-height: 20px;
	}

	::-webkit-scrollbar {
		width: 10px;
		background: rgba(125, 131, 230, 0.1);
		border-radius: 10px;
	}

	/* 滚动条轨道 */
	::-webkit-scrollbar-track {
		// background: rgba(125, 131, 230, 0.3);
		border-radius: 10px;
	}

	/* 滚动条滑块 */
	::-webkit-scrollbar-thumb {
		background: rgba(125, 131, 230, 0.3);
		border-radius: 10px;
		border: 1px solid rgba(118, 124, 216, 0.3);
	}

	/* 滚动条滑块：悬停效果 */
	::-webkit-scrollbar-thumb:hover {
		// background: #a8a8a8;
	}



	.sdsf {
		font-weight: 400;
		font-size: 14px;
		color: #FFFFFF;
		text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
		margin-top: 15px;
	}

	.tit3 {
		width: 340px;
		font-weight: 400;
		font-size: 11px;
		color: #FFFFFF;
		text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
	}

	.tit2 {
		font-weight: 400;
		font-size: 11px;
		color: #9FA4AA;
		text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
		margin: 2px 0 10px 0;
	}

	.tit1 {
		font-weight: 500;
		font-size: 14px;
		color: #FFFFFF;
		text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
	}

	.popup-list {
		// background: rgba(12, 18, 83, 0.7);
		height: 68vh;
		overflow: hidden;
	}

	.warp3 {
		overflow: hidden;
		flex: 1;

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			width: 95%;
			margin-left: 2.5%;

			// background-color: rgba(255, 255, 255, 0.1);
			// background: rgba(12, 18, 83, 0.7);
			li {
				cursor: pointer;
				margin: 10px 0;
				background: rgba(12, 18, 83, 0.7);
				padding: 10px;
			}

			li:hover {
				// background-color: rgba(1, 168, 255, 1);
			}

		}
	}

	.warp2 {
		width: 90%;
		overflow: hidden;
		flex: 1;
		height: 71vh;

		.item {}

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			width: 100%;
			// background-color: rgba(255, 255, 255, 0.1);

			li {
				cursor: pointer;
			}

			li:hover {
				// background-color: rgba(1, 168, 255, 1);
			}

		}
	}

	.warp {
		height: 600px !important;
		width: 90%;
		overflow: hidden;
		flex: 1;

		.item {
			padding: 0 0 5% 0;
		}

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			width: 100%;
			// background-color: rgba(255, 255, 255, 0.1);

			li {
				padding: 0 5% 0 5%;
				cursor: pointer;
			}

			li:hover {
				// background-color: rgba(1, 168, 255, 1);
			}

		}
	}

	.people-count {
		font-weight: 400;
		font-size: 14px;
		color: #FFFFFF;
		line-height: 24px;
		text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
		margin-left: 10px;
	}

	.count-people {
		width: 360px;
		height: 36px;
		background: rgba(255, 255, 255, 0.2);
		border-radius: 18px 18px 18px 18px;
	}

	.peo-top-desc2 {
		font-weight: 400;
		font-size: 14px;
		color: #a3a3a3;
		line-height: 24px;
		text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
	}

	.peo-top-name {
		font-weight: 500;
		font-size: 18px;
		color: #FFFFFF;
		line-height: 24px;
		text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
	}

	.botto {
		width: 100%;
		position: fixed;
		bottom: 20px;
	}

	.dj-cont {
		width: 475px;
		font-weight: 400;
		font-size: 15px;
		color: #FFFFFF;
		line-height: 24px;
		text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
		text-indent: 2ch;
	}

	.info-title {
		font-weight: 700;
		font-size: 18px;
		background: linear-gradient(287.42063399853026deg, #EBB36C 0%, #FFE6C7 27%, #FFE6C7 81%, #FDFFE8 100%);
		-webkit-background-clip: text;
		/*将设置的背景颜色限制在文字中*/
		-webkit-text-fill-color: transparent;
		/*给文字设置成透明*/
	}
</style>