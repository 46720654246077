<template>
	<div class="">
		<santuoMap style="height: 100%;width: 100%;" ref="map"></santuoMap>
		<div class="botto flex justc alit">
			<!-- <img :src="menuIndex != 1 ? 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E7%BB%84%2012714%402x.png' : 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E7%BB%84%2012714%402x%281%29.png'"
				class="menu" alt="" @click="changeMenuIndex(1)" /> -->
			<!-- 	<img :src="menuIndex == 2 ? 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E7%BB%84%2012715%402x.png' : 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E7%BB%84%2012624%402x.png'"
				class="menu" alt="" @click="changeMenuIndex(2)" />
			<img :src="menuIndex == 3 ? 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E7%BB%84%2012625%402x%282%29.png' : 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E7%BB%84%2012625%402x%281%29.png'"
				class="menu" alt="" @click="changeMenuIndex(3)" />
			<img :src="menuIndex != 4 ? 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E7%BB%84%2012623%402x%282%29.png' : 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E7%BB%84%2012623%402x%283%29.png'"
				class="menu" alt="" @click="changeMenuIndex(4)" /> -->


			<img :src="menuIndex == 8 ? 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E8%84%90%E6%A9%99%E4%BA%A7%E4%B8%9A-check3.png' : 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E8%84%90%E6%A9%99%E4%BA%A7%E4%B8%9A.png'"
				class="menu" alt="" @click="changeMenuIndex(8)" />
			<img :src="menuIndex != 5 ? 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E5%86%9C%E6%97%85%E8%9E%8D%E5%90%88.png' : 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E5%86%9C%E6%97%85%E8%9E%8D%E5%90%88-check.png'"
				class="menu" alt="" @click="changeMenuIndex(5)" />
			<img :src="menuIndex != 6 ? 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E7%BB%84%2012627%402x%282%29.png' : 'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/menu/%E7%BB%84%2012627%402x%283%29.png'"
				class="menu" alt="" @click="changeMenuIndex(6)" />
		</div>

		<div class="right flexCol">
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/summary/%E5%AE%B9%E5%99%A8%20323%402x.png"
				style="width: 110px;height: 93px;cursor: pointer;" alt="" />
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/summary/%E5%AE%B9%E5%99%A8%20344%402x.png"
				style="width: 110px;height: 93px;margin-top: 20px;cursor: pointer;" alt="" />
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/summary/%E5%AE%B9%E5%99%A8%20345%402x.png"
				style="width: 110px;height: 93px;margin-top: 20px;cursor: pointer;" alt=""
				@click="changeMenuIndex(7)" />
			<!-- <img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/summary/%E5%AE%B9%E5%99%A8%20346%402x.png"
				style="width: 110px;height: 93px;margin-top: 20px;cursor: pointer;" alt="" /> -->
		</div>

		<div class="popup-info flexCol alit" v-if="menuIndex == 3" style="overflow: hidden;height: 80%;">
			<img src="../../static/back.png" class="popup-back" style="width: 40px;height: 40px;" alt=""
				@click="changeMenuIndex(8)" />
			<div class="title" style="margin: 3vh 0 0 0;">销售行情</div>
			<sales style="width: 100%;height: 20%;"></sales>
			<div class="title" style="margin: 3vh 0 1vh 0;">脐橙销售大户</div>
			<div class="flex justb zzh-title" style="margin-top: 20rpx;">
				<div class="">
					销售户
				</div>
				<div class="">
					销售额
				</div>
			</div>
			<div class="zzh-content flexCol alit">
				<vue-seamless-scroll :data="zzdhList" class="warp" :class-option="classOption">
					<ul class="item">
						<li v-for="(item, index) in zzdhList" :key="index" class="flex alit justb"
							style="height: 40px;">
							<div class="flex alit">
								<img src="../../static/right.png" style="width:  28px;height: 28px;" mode="">
								</img>
								<div class="zzh-name">
									{{item.name}}
								</div>
							</div>
							<div class="zzh-mu">
								{{item.m}}万元
							</div>
						</li>
					</ul>
				</vue-seamless-scroll>
			</div>
		</div>
		<div class="popup-info flexCol alit" v-if="menuIndex == 8" style="overflow: hidden;height: 53%;">
			<img src="../../static/close2.png" class="popup-close" style="width: 30px;height: 30px;" alt=""
				@click="changeMenuIndex(menuIndex)" />

			<div class="flexCol alit justc">
				<div class="cy-one" @click="changeMenuIndex(2)"></div>
				<div class="flex">
					<div class="cy-two" @click="changeMenuIndex(3)"></div>
					<div class="cy-three" @click="changeMenuIndex(4)"></div>
				</div>
			</div>
		</div>
		<div class="popup-info flexCol alit" v-if="menuIndex == 4" style="overflow: hidden;height: 80%;">
			<img src="../../static/back.png" class="popup-back" style="width: 40px;height: 40px;" alt=""
				@click="changeMenuIndex(8)" />
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/%E7%BB%84%2012722%20%281%29.png"
				style="width: 100%;margin-top: 5vh;" alt="" />
		</div>
		<div class="popup-info flexCol alit" v-if="menuIndex == 5" style="overflow: hidden;">
			<img src="../../static/close2.png" class="popup-close" style="width: 30px;height: 30px;" alt=""
				@click="changeMenuIndex(menuIndex)" />
			<div class="title" style="margin: 3vh 0 0 0;">导览图</div>
			<img v-image-preview
				src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/347ac266905523204b3ef16f11e718e.jpg"
				style="width: 70%;margin-top: 1vh;" alt="" />
			<div class="title" style="margin: 2vh 0 0 0;">景区介绍</div>
			<div class="info-jj">
				2021年以来，深度挖掘三沱村脐橙文化、移民文化等特色文化内涵，打造出一个全村域、开放式、典范类2A级景区，目前正在申报创建“4A”景区。建成四公里滨江廊道，培育5家临江民宿、精品农家乐，建设脐橙采摘观光基地，形成了以采摘游玩、直播带货、橙旅融合为一体的乡村旅游新模式；建成三峡移民精神传承馆，打造黄葛树议事点，围绕美丽家园102省道示范线建设项目，打造美丽庭院，绘制移民精神文化墙；利用临水搬迁闲置房、江南大道改道闲置集体用地和大水田小二型水库，合作打造出优质的农旅融合业态。
			</div>
			<img v-image-preview
				src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/summary/%E6%99%AF%E5%8C%BA.jpg"
				style="width: 475px;margin-top: 1vh;" alt="" />
		</div>
		<div class="popup-info flexCol alit" v-if="menuIndex == 2" style="overflow: hidden;height: 80%;">
			<img src="../../static/back.png" class="popup-back" style="width: 40px;height: 40px;" alt=""
				@click="changeMenuIndex(8)" />
			<div class="title" style="margin: 3vh 0 1vh 0;">脐橙概况</div>
			<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/%E7%9F%A9%E5%BD%A2%201397%20%282%29.png"
				style="width: 90%;" alt="" />
			<div class="contt">目前，三沱村脐橙种植面积达5940亩，脐橙树近30万棵，年产值超1.2亿元，村民年人均纯收入近3万元。</div>
			<generalSituation style="width: 100%;height: 40%;margin-top: 3%;"></generalSituation>
		</div>
		<div class="popup-info flexCol alit" v-if="menuIndex == 6" style="overflow: hidden;height: 50%;">
			<img src="../../static/close2.png" class="popup-close" style="width: 30px;height: 30px;" alt=""
				@click="changeMenuIndex(menuIndex)" />
			<div class="title" style="margin: 3vh 0 0 0;">公众号</div>
			<div class="flexCol alit">
				<img v-image-preview
					src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/434d10e11001bccc14faf27bea3a8ae.jpg"
					style="width: 280px;height: 280px; margin-top: 5vh;" alt="" />
				<div class="gzh">扫码关注公众号</div>
			</div>

		</div>
		<div class="popup-info flexCol alit" v-if="menuIndex == 7" style="overflow: hidden;height: 80%;">
			<img src="../../static/close2.png" class="popup-close" style="width: 30px;height: 30px;" alt=""
				@click="changeMenuIndex(menuIndex)" />
			<div class="title" style="margin: 3vh 0 0 0;">物联网数据发布</div>
			<div class="flexCol alit" style="position: relative;width: 500px;margin-top: 4%;">
				<div class="flexCol alit" style="position: absolute;width: 500px;height: 190px;">
					<div class="flex justb" style="width: 86%;">
						<div class="wl-left">14km/h 风速</div>
						<div class="wl-right">风向 东南风</div>
					</div>
					<div class="flex justb" style="width: 95%;">
						<div class="wl-left">37℃ 大气温度</div>
						<div class="wl-right">大气湿度 56%</div>
					</div>
					<div class="flex justb" style="width: 100%;">
						<div class="wl-left">1012hPa 气压</div>
						<div class="wl-right">降雨量 14mm</div>
					</div>
					<div class="flex justb" style="width: 90%;">
						<div class="wl-left">12lux 光照度</div>
						<div class="wl-right">太阳辐射 1.4W/㎡</div>
					</div>
				</div>
				<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/%E7%BB%84%2012748.png"
					style="width: 400px;height: 190px;margin-top: 20px;" />
			</div>
			<div class="flexCol alit" style="position: relative;width: 500px;margin-top: 15%;">

				<div class="flexCol alit" style="position: absolute;width: 500px;height: 190px;">

					<div class="flex justb" style="width: 100%;">
						<div class="wl-bott">
							<div class="tt">37℃</div>
							<div class="wd">温度</div>
						</div>
						<div class="wl-bott">
							<div class="tt">90%</div>
							<div class="wd">湿度</div>
						</div>
						<div class="wl-bott2">
						</div>
						<div class="wl-bott">
							<div class="tt">7.8</div>
							<div class="wd">PH值</div>
						</div>
						<div class="wl-bott">
							<div class="tt">125/m</div>
							<div class="wd">电导率</div>
						</div>
					</div>

					<div class="flex justb" style="width: 100%;margin-top: 80px;">
						<div class="wl-bott">
							<div class="tt">1.4g/kg</div>
							<div class="wd">盐分</div>
						</div>
						<div class="wl-bott">
							<div class="tt">3.4g/kg</div>
							<div class="wd">氮</div>
						</div>
						<div class="wl-bott2">
						</div>
						<div class="wl-bott">
							<div class="tt">1.4g/kg</div>
							<div class="wd">磷</div>
						</div>
						<div class="wl-bott">
							<div class="tt">5.9/kg</div>
							<div class="wd">钾</div>
						</div>
					</div>

				</div>
				<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/%E5%AE%B9%E5%99%A8%20345.png"
					style="width: 148px;height: 150px;margin-top: 80px;" />
			</div>

		</div>
	</div>
</template>

<script>
	import santuoMap from '../components/tourismMap.vue'
	import sales from '../components/tourism/sales.vue'
	import generalSituation from '../components/tourism/generalSituation.vue'
	import vueSeamlessScroll from 'vue-seamless-scroll'
	export default {
		components: {
			santuoMap,
			vueSeamlessScroll,
			sales,
			generalSituation
		},
		data() {
			return {
				classOption: {
					limitMoveNum: 10,
					hoverStop: true,
					waitTime: 3000,
					step: 0.2
				},
				menuIndex: 0,
				zzdhList: [{
						name: '奉节县驰浩水果种植有限公司',
						xl: '450',
						m: 311.9
					},
					{
						name: '奉节县坤双脐橙种植专业合作社',
						xl: '320',
						m: 200
					},
					{
						name: '奉节县显海脐橙种植有限公司',
						xl: '300',
						m: 200
					},
					{
						name: '奉节县赢获生态农业有限公司',
						xl: '550',
						m: 408.5
					},
					{
						name: '奉节县众扬水果种植专业合作社',
						xl: '1600',
						m: 1000
					},
					{
						name: '奉节县紫塘脐橙专业合作社',
						xl: '500',
						m: 350
					}, {
						name: '奉节县驰浩水果种植有限公司',
						xl: '450',
						m: 311.9
					},
					{
						name: '奉节县坤双脐橙种植专业合作社',
						xl: '320',
						m: 200
					},
					{
						name: '奉节县显海脐橙种植有限公司',
						xl: '300',
						m: 200
					},
					{
						name: '奉节县赢获生态农业有限公司',
						xl: '550',
						m: 408.5
					},
					{
						name: '奉节县众扬水果种植专业合作社',
						xl: '1600',
						m: 1000
					},
					{
						name: '奉节县紫塘脐橙专业合作社',
						xl: '500',
						m: 350
					}
				],
			}
		},
		mounted() {
			if (!sessionStorage.getItem('log')) {
				this.$message.error('请先登录');
				this.$router.replace('/')
			}
		},
		methods: {
			changeMenuIndex(e) {
				if (this.menuIndex == e) {
					this.menuIndex = 0
					this.$refs.map.initLocation()
				} else {
					this.menuIndex = e
					this.$refs.map.changeLocation(e)
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.info-jj {
		width: 475px;
		font-weight: 400;
		font-size: 12px;
		color: #FFFFFF;
		text-indent: 2ch;
		line-height: 20px;
	}

	.cy-two {
		background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/%E8%84%90%E6%A9%99%E9%94%80%E5%94%AE.png');
		width: 200px;
		height: 206px;
		background-size: 100% 100%;
		cursor: pointer;
	}

	.cy-two:hover {
		background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/%E8%84%90%E6%A9%99%E9%94%80%E5%94%AE-check.png');
	}

	.cy-three {
		background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/%E4%BA%A7%E4%B8%9A%E9%93%BE.png');
		width: 200px;
		height: 206px;
		background-size: 100% 100%;
		cursor: pointer;
		margin-left: 20px;
	}

	.cy-three:hover {
		background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/%E4%BA%A7%E4%B8%9A%E9%93%BE-check.png');
	}

	.cy-one {
		background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/%E8%84%90%E6%A9%99%E6%A6%82%E5%86%B5.png');
		width: 200px;
		height: 206px;
		background-size: 100% 100%;
		cursor: pointer;
	}

	.cy-one:hover {
		background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/%E8%84%90%E6%A9%99%E6%A6%82%E5%86%B5-check.png');
	}

	.wd {
		font-weight: 400;
		font-size: 14px;
		color: #FFFFFF;
		margin-top: 20px;
	}

	.tt {
		font-weight: 400;
		font-size: 14px;
		color: #FFFFFF;
	}

	.wl-bott2 {
		width: 80px;
		height: 78px;
	}

	.wl-bott {
		width: 80px;
		height: 78px;
		background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/%E7%BB%84%2012731.png');
		background-size: 100% 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.wl-right {
		width: 146px;
		height: 38px;
		background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/%E7%BB%84%20%281%29.png');
		background-size: 100% 100%;
		line-height: 38px;
		padding-left: 10px;
		font-family: Source Han Sans, Source Han Sans;
		font-weight: 400;
		font-size: 14px;
		color: #FFFFFF;
		margin-bottom: 20px;
	}

	.wl-left {
		width: 146px;
		height: 38px;
		background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/tourism/%E7%BB%84.png');
		background-size: 100% 100%;
		line-height: 38px;
		text-align: right;
		padding-right: 10px;
		font-family: Source Han Sans, Source Han Sans;
		font-weight: 400;
		font-size: 14px;
		color: #FFFFFF;
		margin-bottom: 20px;
	}

	.right {
		position: fixed;
		right: 30px;
		z-index: 99;
		top: 100px;
	}

	.gzh {
		font-weight: 400;
		font-size: 20px;
		color: #FFFFFF;
		line-height: 40px;
		text-align: center;
	}

	.contt {
		width: 90%;
		font-weight: 400;
		font-size: 16px;
		color: #FFFFFF;
		text-indent: 2ch;
		margin: 1vh 0;
	}

	.title {
		font-weight: 700;
		font-size: 20px;
		color: #FFFFFF;
		line-height: 30px;
	}

	.zzh-mu {
		font-weight: 400;
		font-size: 16px;
		color: rgba(255, 255, 255, 0.87);
	}

	.zzh-title {
		width: 400px;
		height: 41px;
		background-color: #094573;
		font-size: 15px;
		font-family: Source Han Sans, Source Han Sans;
		font-weight: 400;
		color: #6FBAFF;
		line-height: 41px;
		padding: 0 30px;
	}

	.zzh-dh {
		margin-left: 15px;
		font-size: 16px;
		font-family: Source Han Sans, Source Han Sans;
		font-weight: 400;
		color: rgba(255, 255, 255, 0.87);
	}

	.zzh-name {
		font-size: 16px;
		font-family: Source Han Sans, Source Han Sans;
		font-weight: 400;
		color: rgba(255, 255, 255, 0.87);
	}

	.zzh-content {
		width: 400px;
		padding: 0 30px;
		height: 55%;
		overflow: hidden;
	}

	.warp {
		width: 115%;
		overflow: hidden;
		flex: 1;

		.item {}

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			width: 100%;
			// background-color: rgba(255, 255, 255, 0.1);

			li {
				cursor: pointer;
			}

			li:hover {
				// background-color: rgba(1, 168, 255, 1);
			}

		}
	}

	.menu {
		width: 266px;
		height: 65px;
		cursor: pointer;
		margin-left: 10px;

	}

	.botto {
		width: 100%;
		position: fixed;
		bottom: 20px;
	}
</style>