<template>
	<div id="app">
		<!-- 定义动画 -->
		<transition :name="transitionName">
			<router-view></router-view>
		</transition>
	</div>
</template>
<script>
	export default {
		name: 'App',
		data() {
			return {
				transitionName: ""
			}
		},
		watch: {
			$route(to, from) {
				if (to.meta.index > 0 && from.meta.index) {
					if (to.meta.index < from.meta.index) {
						this.transitionName = "slide-right";
					} else {
						this.transitionName = "slide-left";
					}
				} else if (to.meta.index == 0 && from.meta.index > 0) {
					this.transitionName = "";
				}
			}
		}
	}
</script>
<style>
	/* 页面切换动画 */
	.slide-right-enter-active,
	.slide-right-leave-active,
	.slide-left-enter-active,
	.slide-left-leave-active {
		/* will-change属性可以提前通知浏览器我们要对元素做什么动画，这样浏览器可以提前准备合适的优化设置 */
		will-change: transform;
		transition: all ease 0.4s;
		-webkit-transition: all ease 0.4s;
		position: absolute;
		width: 100%;
		left: 0;
	}

	.slide-right-enter {
		transform: translateX(-100%);
		-webkit-transform: translateX(-100%);
	}

	.slide-right-leave-active {
		transform: translateX(100%);
		-webkit-transform: translateX(100%);
	}

	.slide-left-enter {
		transform: translateX(100%);
		-webkit-transform: translateX(100%);
	}

	.slide-left-leave-active {
		transform: translateX(-100%);
		-webkit-transform: translateX(-100%);
	}
</style>
<style lang="scss">
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		// text-align: center;
		color: #2c3e50;
	}

	body {
		padding: 0;
		margin: 0;
		border-radius: 0;
	}

	/*每个页面公共css */
	.flex {
		display: flex;
	}

	.flexCol {
		display: flex;
		flex-direction: column;
	}

	.alit {
		align-items: center;
	}

	.justb {
		justify-content: space-between;
	}

	.justc {
		justify-content: center;
	}
	.popup-back{
		position: absolute;
		top: 2%;
		left: 0;
		opacity: 0.8;
		cursor: pointer;
		z-index: 9999;
	}
	.popup-close{
		position: absolute;
		top: 2%;
		right: 0;
		opacity: 0.8;
		cursor: pointer;
		z-index: 9999;
	}

	.popup-info {
		width: 515px;
		height: 80vh;
		background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/santuo/pc/home/%E7%BB%84%20381%402x.png');
		background-size: 100% 100%;
		animation: example1 0.5s ease-out 0s backwards;
		position: fixed;
		top: 10%;
		left: 10%;
	}

	@keyframes example1 {
		0% {
			transform: translate(-100px);
			opacity: 0;
		}

		50% {
			transform: translate();
			opacity: 0.5;
		}

		100% {
			transform: translate(0);
			opacity: 1;
		}
	}
</style>